import { useLPSuspenseQuery } from '../lib/hooks/useLPQuery';

export function useTeamSecuritySnapshotQuery() {
  // TODO: use team's security snapshot query once ready from BE
  return useLPSuspenseQuery<TeamSecuritySnapshot>(['security-snapshot'], '/me/securitySnapshot');
}
type TeamSecuritySnapshot = {
  cyberIqPoints: number;
  learnerId: string;
  levelId: string;
};
