import { useRef, type ComponentProps, type FC } from 'react';
import { NavLink, matchPath, resolvePath, useLocation, useResolvedPath } from 'react-router';
import styled from 'styled-components';
import { type Page } from '../TopBarUI';

export const MobileNavLinks: FC<{ className?: string; pages: Page[]; handleHideMenu: () => void }> = ({
  className,
  pages,
  handleHideMenu,
}) => {
  const resolvedPath = useResolvedPath('', { relative: 'route' });
  const location = useLocation();

  const pagesExt = pages.map((page) => {
    const path = resolvePath(page.path, resolvedPath.pathname);
    const m = matchPath(path.pathname, location.pathname);
    return { ...page, active: !!m };
  });

  const containerRef = useRef<HTMLDivElement>(null);

  return (
    <LinksContainer className={className} ref={containerRef}>
      {pagesExt.map(({ name, path, active }) => (
        <NLinkWrapper
          handleHideMenu={handleHideMenu}
          key={path}
          to={path}
          isActive={active}
          style={{ cursor: active ? 'default' : 'pointer' }}
        >
          {name}
        </NLinkWrapper>
      ))}
    </LinksContainer>
  );
};

const NLink = styled(NavLink)`
  position: relative;
  text-decoration: none;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.normal};
  font-size: ${({ theme }) => theme.fontSizes.heading};
  font-weight: 500;
  height: 100%;
  margin: 0;
  padding: 0;

  &.active {
    color: ${({ theme }) => theme.colors.anchor};

    :before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      height: 32px;
      width: 4px;
      transform: translate(-24px, -25%);
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
      background-color: ${({ theme }) => theme.colors.primary};
    }
  }
`;

const NLinkWrapper: FC<ComponentProps<typeof NLink> & { isActive: boolean; handleHideMenu: () => void }> = ({
  children,
  isActive,
  handleHideMenu,
  ...props
}) => {
  return (
    <NLink onClick={handleHideMenu} {...props}>
      {children}
    </NLink>
  );
};

const LinksContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2em;
`;
