import { useMemo } from 'react';
import { useLanguageContext } from '../lib/hooks/useLanguageContext';
import { toContentEntryRefs } from './util/toContentEntryRefs';
import type { ContentEntryRef } from '../lib/hooks/contentEntry/types/ContentEntryRef';
import type { ContentEntryData } from '../lib/hooks/contentEntry/types/ContentEntryResponse';
import { usePropertyTransition } from '../components/ContentEntryText/usePropertyTransition';

export type TextBlockRefLight =
  | {
      nested: false;
      contentId?: string;
      fallback: string[];
    }
  | {
      nested: true;
      contentId?: string;
      fallback: string[];
      propSubKeyIndexes: Record<string, number>;
    };

export type SnapshotLevel = 'Vulnerable' | 'Determined' | 'Solid' | 'Armored' | 'Unbreakable';
export type SecuritySnapshotLevelKey = `securitySnapshotLevels${SnapshotLevel}`;

const levelSubKeyIndexes = {
  levelN: 0,
  levelName: 1,
  bodyHeading: 2,
  bodyMain: 3,
} as const;

const securitySnapshotLevelRefs = {
  securitySnapshotLevelsVulnerable: {
    nested: true,
    contentId: '380cfd40-dd96-4ee3-b0b0-0584ca7db30f',
    fallback: [
      'Level 1',
      'Vulnerable',
      'Welcome to the start of your cybersecurity journey!',
      "As a VULNERABLE user, it's important you start behaving more securely.  Check out the breakdown below to see how you can improve, earn CyberIQ points and climb the ranks!",
    ],
    propSubKeyIndexes: levelSubKeyIndexes,
  },
  securitySnapshotLevelsDetermined: {
    nested: true,
    contentId: 'a57e7591-e65a-4a40-8beb-3a48ea3ba076',
    fallback: [
      'Level 2',
      'Determined',
      "You're making good progress!",
      'You are DETERMINED and your efforts are starting to pay off. Keep pushing forward and you’ll see even more improvement! Every action you take helps protect our company.',
    ],
    propSubKeyIndexes: levelSubKeyIndexes,
  },
  securitySnapshotLevelsSolid: {
    nested: true,
    contentId: '24191911-5955-42c9-898a-af3d3a6bdd80',
    fallback: [
      'Level 3',
      'Solid',
      "You're doing well!",
      "You are SOLID and your consistent efforts are strengthening our company's security. Keep up the good work and continue to build on your progress!",
    ],
    propSubKeyIndexes: levelSubKeyIndexes,
  },
  securitySnapshotLevelsArmored: {
    nested: true,
    contentId: 'cfba9c39-fee3-42a6-9403-e29bd4949b0d',
    fallback: [
      'Level 4',
      'Armored',
      "You're doing fantastic!",
      'You are ARMORED and your dedication to cybersecurity is making a significant impact. Thank you for helping to keep our company safe. Keep it up!',
    ],
    propSubKeyIndexes: levelSubKeyIndexes,
  },
  securitySnapshotLevelsUnbreakable: {
    nested: true,
    contentId: 'a49438ec-6e85-4e85-8a16-ee04be7f2efb',
    fallback: [
      'Level 5',
      'Unbreakable',
      'Congratulations!',
      'You are UNBREAKABLE! Your commitment to cybersecurity is exemplary. Thank you for being a role model and keeping our company secure!',
    ],
    propSubKeyIndexes: levelSubKeyIndexes,
  },
} as const satisfies Record<SecuritySnapshotLevelKey, TextBlockRefLight>;

type ScoreBreakdownItem =
  | 'Completion'
  | 'Engagement'
  | 'Knowledge'
  | 'PhishingReports'
  | 'PhishingClicks'
  | 'Tooltips'
  | 'EmptyDataCard';

type ScoreBreakdownKey = `scoreBreakdown${ScoreBreakdownItem}`;

const scoreBreakdownRefs = {
  scoreBreakdownCompletion: {
    nested: true,
    propSubKeyIndexes: {
      heading: 0,
      body: 1,
    },
    fallback: [
      'Complete all training',
      'You have one overdue mandatory cybersecurity training. Completing this training on time is crucial to keeping both you and the company safe and compliant.',
    ],
    contentId: '3b9f394d-9c48-4249-94f8-636b0f2dd2b3',
  },
  scoreBreakdownEngagement: {
    nested: true,
    propSubKeyIndexes: {
      heading: 0,
      body: 1,
    },
    fallback: [
      'Maintain high engagement',
      'Your engagement is up last 30 days with 2%. Great! Keep asking questions, completing modules and sharing tips with your colleagues This helps the company and you to stay safe.',
    ],
    contentId: 'b102dff0-fa39-4f7b-bacb-2923a42d1b88',
  },
  scoreBreakdownKnowledge: {
    nested: true,
    propSubKeyIndexes: {
      heading: 0,
      body: 1,
    },
    fallback: [
      'Build your knowledge',
      'Your training knowledge has declined over last 30 days and is lower compared to average.In future sessions make sure to revisit the training materials before you take the knowledge assessment questions.',
    ],
    contentId: '5c47e93a-b376-4ab3-8d72-d69548dd81ae',
  },
  scoreBreakdownPhishingReports: {
    nested: true,
    propSubKeyIndexes: {
      heading: 0,
      body: 1,
    },
    fallback: [
      'Report attacks',
      'In the past 30 days, you spotted and reported 3 out of 5 phishing simulations. Great job! Keep it up to earn more points—each identified phish earns you 250 CyberIQ points.',
    ],
    contentId: '7416b1ca-37ea-45c1-b7b6-e6a6b408fb89',
  },
  scoreBreakdownPhishingClicks: {
    nested: true,
    propSubKeyIndexes: {
      heading: 0,
      body: 1,
    },
    fallback: [
      'Avoid clicking',
      'There were no simulations issued in last 30 days. Stay vigilant and earn points by reporting them!',
    ],
    contentId: '2a32edcc-6c53-4b18-ba25-bf98a0e1f178',
  },
  scoreBreakdownTooltips: {
    nested: true,
    propSubKeyIndexes: {
      dateLabel: 0,
      compromised: 1,
      reported: 2,
      noAction: 3,
      result: 4,
    },
    fallback: [
      'Simulation date',
      'You did not recognise this was a simulation and got compromised.',
      'You reported the simulation.',
      'You did not perform any action. Reporting simulations when you spot them is crucial. Always be proactive in ensuring effective monitoring and response!',
      'Simulations performance',
    ],
    contentId: '4516f0a9-57dd-42a9-8b51-b5ec269393bf',
  },
  scoreBreakdownEmptyDataCard: {
    nested: true,
    propSubKeyIndexes: {
      heading: 0,
      description: 1,
    },
    fallback: [
      'No behaviors tracked yet',
      'Your progress will appear here as soon as you start engaging in activities.',
    ],
    contentId: '3bc9e105-5ed6-4bfd-97a3-afcf7e3f0f96',
  },
} as const satisfies Record<ScoreBreakdownKey, TextBlockRefLight>;

const simpleTextBlockRefs = {
  ...scoreBreakdownRefs,
  ...securitySnapshotLevelRefs,
  menuSystem: {
    nested: true,
    contentId: '79679d27-4477-4cda-ba86-f3506371531d',
    fallback: [
      'Overview',
      'Leaderboard',
      'Challenges & Activities',
      'Manager view',
      'User view',
      'User',
      'Manager',
      'Sign out',
    ],
    propSubKeyIndexes: {
      overview: 0,
      leaderboard: 1,
      challengesActivities: 2,
      managerView: 3,
      userView: 4,
      user: 5,
      manager: 6,
      signOut: 7,
    },
  },
  sectionTitles: {
    nested: true,
    contentId: 'b583e226-b5d5-4ca8-9ecf-a4118334d504',
    fallback: [
      'SECURITY SNAPSHOT',
      'LEADERBOARD',
      'SCORE BREAKDOWN',
      'YOUR TRAINING',
      'ACTIVITY OVERVIEW',
      'ACTIVITIES',
    ],
    propSubKeyIndexes: {
      securitySnapshot: 0,
      leaderboard: 1,
      scoreBreakdown: 2,
      yourTraining: 3,
      activityOverview: 4,
      activities: 5,
    },
  },
  securitySnapshot: {
    nested: true,
    contentId: 'd0e1be8e-841b-4534-aab6-6cf40fa3eeae',
    fallback: ['CyberIQ Points'],
    propSubKeyIndexes: {
      cyberiqPoints: 0,
    },
  },
  leaderboard: {
    nested: true,
    contentId: '74f39d99-e463-4c39-af93-866b0725534f',
    fallback: [
      'My team',
      'My department',
      'My company',
      'All time',
      'This month',
      'View more...',
      'Rank',
      'Name',
      'Badges',
      'Level',
      'Points',
      'How to earn',
      'My country',
      'Departments',
      'No badges yet',
    ],
    propSubKeyIndexes: {
      myTeam: 0,
      myDepartment: 1,
      myCompany: 2,
      allTime: 3,
      thisMonth: 4,
      viewMore: 5,
      rank: 6,
      name: 7,
      badges: 8,
      level: 9,
      points: 10,
      howToEarn: 11,
      myCountry: 12,
      departments: 13,
      noBadges: 14,
    },
  },
  yourTraining: {
    nested: true,
    contentId: '62fceac2-287d-4869-8ada-8c3bc15693e8',
    fallback: [
      'Mandatory',
      'Voluntary',
      'Completed',
      'Urgent:',
      'Due date:',
      'Start now',
      'Revisit',
      'Resume',
      'No training assigned for now',
      'No trainings completed',
      'No voluntary trainings for now',
    ],
    propSubKeyIndexes: {
      mandatory: 0,
      voluntary: 1,
      completed: 2,
      urgent: 3,
      dueDate: 4,
      startNow: 5,
      revisit: 6,
      resume: 7,
      noMandatoryTrainings: 8,
      noCompletedTrainings: 9,
      noVoluntaryTrainings: 10,
    },
  },
  activityOverview: {
    nested: true,
    contentId: 'c5e93e0d-e7a9-49d4-8725-e323b8511ca6',
    fallback: [
      'Your points',
      'Company average',
      'Recent activity',
      'Date',
      'Points',
      'Reported Phishing Simulation',
      'Completed Cyber Assessment',
      'Optional Phishing Game Completed',
      'Reported Suspected Phish',
      'Mandatory Training',
    ],
    propSubKeyIndexes: {
      yourPoints: 0,
      companyAverage: 1,
      recentActivity: 2,
      date: 3,
      points: 4,
      reportedPhishingSimulation: 5,
      completedCyberAssessment: 6,
      optionalPhishingGameCompleted: 7,
      reportedSuspectedPhish: 8,
      mandatoryTraining: 9,
    },
  },
  activityStatus: {
    nested: true,
    contentId: '158bcd09-56ae-433c-ae0e-9f9af7336ca8',
    fallback: [
      '-',
      'Completion of a mandatory module.',
      'Completed your assigned training on time.',
      'You were highly engaged in your training.',
      'You showed high levels of knowledge in your training.',
      'Completed the training immediately after it was assigned.',
      'Commented on how this behavior will impact your productivity.',
      'Contributed to training feedback.',
      "Didn't provide any feedback at the end of your training.",
      'Rated the training.',
      'Successfully reported a phishing simulation.',
      'Clicked on a phishing simulation.',
      'Gave away your credentials in a phishing simulation.',
      "Congratulations on being recognized as a 'Defender'!",
      'Repeatedly clicked on a link during phishing simulations!',
      'Reported an external spam email.',
      "You've shown great initiative by successfully completing voluntary training.",
      'Watched a Cyberbulletin.',
      'Completed the Initial Assessment during training.',
      'Reported a phishing simulation even after being compromised.',
      'Failed to complete the training on time.',
      'User completed all training on time',
      'User did not receive any trainings',
      'User did not complete all trainings on time',
      'User reported a phishing simulations',
      'User did not receive phishing simulations',
      'User received simulation but did not report it',
      'User avoided phishing simulation clicks',
      'User did not receive phishing simulations',
      'User clicked phishing simulations',
      'User engaged is high',
      'User engagement is not high or low',
      'User engaged low',
      'User has high knowledge',
      'User knowledge is not high or low',
      'User has low knowledge',
    ],
    propSubKeyIndexes: {
      none: 0,
      mandatoryModuleCompleted: 1,
      fullCampaignCompleted: 2,
      campaignCompletedWithHighEngagement: 3,
      campaignCompletedWithHighKnowledge: 4,
      campaignCompletedOnDayWhenOpened: 5,
      productivityCommentLeft: 6,
      commentLeftAtModuleEnd: 7,
      commentNotLeftAtModuleEnd: 8,
      ratingLeft: 9,
      phishingSimulationReported: 10,
      phishingSimulationClicked: 11,
      credentialsGivenInSimulation: 12,
      defenderTagGranted: 13,
      linkRepeatedlyClicked: 14,
      externalSpamReported: 15,
      optionalTrainingCompleted: 16,
      cyberbulletinWatched: 17,
      initialAssessmentCompleted: 18,
      emailReportedAfterCompromised: 19,
      trainingIncompleteAfterDueDate: 20,
      sbCompletionSuccess: 21,
      sbCompletionNeutral: 22,
      sbCompletionFail: 23,
      sbPhishingReportsSuccess: 24,
      sbPhishingReportsNeutral: 25,
      sbPhishingReportsFail: 26,
      sbPhishingClicksSuccess: 27,
      sbPhishingClicksNeutral: 28,
      sbPhishingClicksFail: 29,
      sbEngagementSuccess: 30,
      sbEngagementNeutral: 31,
      sbEngagementFail: 32,
      sbKnowledgeSuccess: 33,
      sbKnowledgeNeutral: 34,
      sbKnowledgeFail: 35,
    },
  },
  chart: {
    nested: true,
    contentId: '961775e8-3613-4a26-a578-719ba5e6659a',
    fallback: ["There's no data here yet"],
    propSubKeyIndexes: {
      chartNoData: 0,
    },
  },
  activities: {
    nested: true,
    contentId: 'a24c1a9e-28d0-46f9-a88e-f23b12b498e1',
    fallback: ['Start now', '{{completionMinutes}} mins'],
    propSubKeyIndexes: {
      startNow: 0,
      completionminutesMins: 1,
    },
  },
  table: {
    nested: true,
    contentId: 'd4d83541-b5bd-4512-a1a5-5ddef70fef84',
    fallback: ['No data to show', 'Export activity log (CSV)', 'Filter active'],
    propSubKeyIndexes: {
      noItems: 0,
      exportActivityLogCsv: 1,
      filterActive: 2,
    },
  },
  managerSectionTitles: {
    nested: true,
    contentId: 'ee45af36-1218-4794-8cd1-ec4300129964',
    fallback: [
      "YOUR TEAM'S MONTHLY SECURITY SNAPSHOT",
      'TEAM BEHAVIORS',
      'TEAM MEMBERS',
      'CYBERSECURITY TRAINING',
      'COMPLETION STATUS',
    ],
    propSubKeyIndexes: {
      securitySnapshot: 0,
      teamBehaviors: 1,
      teamMembers: 2,
      cybersecurityTraining: 3,
      completionStatus: 4,
    },
  },
  managerTeamSecuritySnapshot: {
    nested: true,
    contentId: '288a4fa7-b89b-4b0c-acef-e21e7c8b7330',
    fallback: [
      "Team's CyberIQ score",
      'CyberIQ level distribution',
      'Benchmark org',
      'The CyberIQ score reflects the team’s collective cybersecurity performance, summarizing individual actions and behaviors into an average.',
      "The CyberIQ level distribution shows the team’s overall performance, highlighting the team's average level and how many users fall into each bracket.",
    ],
    propSubKeyIndexes: {
      cyberiqScore: 0,
      cyberiqLevel: 1,
      benchmarkOrg: 2,
      cyberiqScoreTooltip: 3,
      cyberiqLevelTooltip: 4,
    },
  },
  managerTeamBehaviors: {
    nested: true,
    contentId: 'a07f4ea6-4b48-4a69-b31d-a036f8749e8f',
    fallback: ['Team size', 'People with weak behavior', 'People with strong behavior', 'Behaviors', 'Trend', 'Action'],
    propSubKeyIndexes: {
      teamSize: 0,
      weakBehavior: 1,
      strongBehavior: 2,
      behaviors: 3,
      trend: 4,
      action: 5,
    },
  },
  managerTeamCyberSecurityTraining: {
    nested: true,
    contentId: '8f1be2b2-0a32-4873-b812-4f4846878d54',
    fallback: [
      'Encourage your team to complete their cybersecurity training to build strong behaviors and boost their cyber resilience.',
      'Nudge',
      'Revisit',
      'Completed',
      'See training completions',
    ],
    propSubKeyIndexes: {
      description: 0,
      nudge: 1,
      revisit: 2,
      completed: 3,
      seeTrainingCompletions: 4,
    },
  },
  managerTrainingCompletionDialog: {
    nested: true,
    contentId: '34f5ecc5-fba6-47fe-90c9-a0c0656dc05f',
    fallback: ['Handling sensitive data', 'Overdue', 'Nudge all non completers', 'Non completers'],
    propSubKeyIndexes: {
      handlingSesitiveData: 0,
      overdue: 1,
      nudgeAllNonCompleters: 2,
      nonCompleters: 3,
    },
  },
} as const satisfies Record<string, TextBlockRefLight>;

const contentEntryRefs = toContentEntryRefs(simpleTextBlockRefs);

export type ContentEntryRefs = typeof contentEntryRefs;
export type ContentEntryRefsKey = keyof ContentEntryRefs;

type ContentEntryRefsTuple<K extends (ContentEntryRefsKey | undefined)[]> = {
  [Idx in keyof K]: K[Idx] extends ContentEntryRefsKey
    ? (ContentEntryRefs[K[Idx]] & { languageCode: string }) | undefined
    : undefined;
} & {
  length: K['length'];
};

export function useContentEntryRefs<K extends (ContentEntryRefsKey | undefined)[]>(
  keys: readonly [...K] | readonly [],
): ContentEntryRefsTuple<K> {
  const [languageCode] = useLanguageContext();
  const [, lc] = usePropertyTransition(languageCode);

  return useMemo(
    () => (lc ? getContentEntryRefs(keys, lc) : (keys.map(() => undefined) as ContentEntryRefsTuple<K>)),
    [keys, lc],
  );
}
export function getContentEntryRefs<K extends (ContentEntryRefsKey | undefined)[]>(
  keys: readonly [...K] | readonly [],
  languageCode: string,
): ContentEntryRefsTuple<K> {
  return keys.map((key) => (key ? { ...contentEntryRefs[key], languageCode } : undefined)) as ContentEntryRefsTuple<K>;
}
export function getAllContentEntryRefs(languageCode: string) {
  // return keys.map((key) => (key ? { ...contentEntryRefs[key], languageCode } : undefined)) as ContentEntryRefsTuple<K>;
  return Object.values(contentEntryRefs).flatMap((value) => {
    if (value.contentId == null || value.moduleId == null) {
      return [];
    }
    const retVal: ContentEntryRef<ContentEntryData> = {
      ...value,
      contentId: value.contentId,
      moduleId: value.moduleId,
      languageCode,
    };
    return [retVal];
  });
}
