import { createContext, useContext, type PropsWithChildren } from 'react';
import { useSecretCodeToggle } from '../useSecretCodeToggle';

function createColorThemeContext() {
  const ColorThemeContent = createContext<'light' | 'dark'>('light');
  const ColorThemeContentProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const t = useSecretCodeToggle('DARKMODE');
    return <ColorThemeContent.Provider value={t ? 'dark' : 'light'}>{children}</ColorThemeContent.Provider>;
  };
  function useColorTheme() {
    const theme = useContext(ColorThemeContent);
    return theme;
  }
  return { ColorThemeContentProvider, useColorTheme };
}

export const { ColorThemeContentProvider, useColorTheme } = createColorThemeContext();
