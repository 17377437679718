import { useLPSuspenseQuery } from '../lib/hooks/useLPQuery';

export function useManagerTeamCampaignsQuery(showAll: boolean) {
  return useLPSuspenseQuery<UseCampaignsQueryResponse>(
    ['campaigns', showAll],
    // TODO: Replace with the actual API endpoint once manager API support available from BE.
    `/campaigns?showAll=${showAll.toString().toLowerCase()}`,
  );
}

export type UseCampaignsQueryResponse = {
  mandatory: ManagerTeamCampaignListResponse[];
  completed: ManagerTeamCampaignListResponse[];
};

export type ManagerTeamCampaignListResponse = {
  id: string;
  name: string;
  status: 'notStarted' | 'inProgress' | 'completed';
  voluntary: boolean;
  dueDate: string;
  isStarted: boolean;
  modulesProgress: number;
  modulesTotal: number;
  totalLearners?: number;
  learnersCompletedTotal?: number;
  estimatedCompletionTime?: number;
  campaignUrl: string;
};
