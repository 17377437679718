import React from 'react';
import DownloadIcon from '../../../assets/Download.svg';
import styled from 'styled-components';

export type DownloadButtonProps = {
  text: string | React.ReactNode;
  className?: string;
  onClick: () => void;
};

const DownloadButton: React.FC<DownloadButtonProps> = ({ onClick, text, className }) => {
  return (
    <StyledLinkButton onClick={onClick} className={className}>
      <Content>
        <Text>{text}</Text>
        <DownloadIcon />
      </Content>
    </StyledLinkButton>
  );
};

export default DownloadButton;

const StyledLinkButton = styled.a`
  cursor: pointer;
`;

const Content = styled.div`
  display: flex;
  align-items: start;
  justify-content: center;
  gap: 8px;
`;

const Text = styled.span`
  font-size: 12px;
  text-decoration: underline;
  @media (max-width: 600px) {
    display: none;
  }
`;
