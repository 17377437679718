import type { FC, PropsWithChildren } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import TimesIcon from '../../assets/TimesIcon.svg';
import ReactDOM from 'react-dom';
import { useEscapeKeyHandler } from '../../lib/hooks/useEscapeKeyHandler';

const modalRoot = document.getElementById('modal');

type ModalProps = {
  isOpen: boolean;
  onClose: () => void;
  title?: string;
  hideCloseButton?: boolean;
  zIndex?: number;
  clickOutsideClose?: boolean;
  escapeKeyClose?: boolean;
  disableBodyScroll?: boolean;
} & PropsWithChildren;

export const Modal: FC<ModalProps> = ({
  title,
  isOpen,
  onClose,
  hideCloseButton,
  zIndex,
  children,
  clickOutsideClose,
  escapeKeyClose,
  disableBodyScroll,
}: ModalProps) => {
  const showModalHeader = title || !hideCloseButton;
  useEscapeKeyHandler({ active: !!escapeKeyClose, onEscapePressed: onClose });

  if (!isOpen || !modalRoot) {
    return null;
  }

  const ModalTemplate = (
    <>
      {disableBodyScroll && <PreventBodyScrollStyle />}
      <ModalOverlay zIndex={zIndex} onClick={() => clickOutsideClose && onClose()}>
        <ModalContainer onClick={(e) => e.stopPropagation()}>
          {showModalHeader && (
            <ModalHeader>
              {title && <Title>{title}</Title>}
              {!hideCloseButton && (
                <CloseButton onClick={onClose}>
                  <TimesIcon />
                </CloseButton>
              )}
            </ModalHeader>
          )}
          {children}
        </ModalContainer>
      </ModalOverlay>
    </>
  );

  return ReactDOM.createPortal(ModalTemplate, modalRoot);
};

const ModalOverlay = styled.div<{ zIndex?: number }>`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.25);
  z-index: ${({ zIndex }) => (zIndex ? zIndex : 999)};
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8px;
  margin-bottom: 16px;
`;

const Title = styled.span`
  font-size: ${({ theme }) => theme.fontSizes.medium};
  font-weight: 600;
  color: ${({ theme }) => theme.colors.heading};
  text-transform: uppercase;
`;

const ModalContainer = styled.div`
  position: relative;
  overflow-x: hidden;
  min-width: 388px;
  min-height: 185px;
  max-height: 95vh;
  max-width: 90vw;
  padding: 24px;
  display: flex;
  border-radius: 16px;
  flex-direction: column;
  background: ${({ theme }) => theme.colors.panelBackground};
  box-shadow: ${({ theme }) => theme.shadow.light};
`;

const CloseButton = styled.button`
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  background: none;
  border: none;
  font-size: ${({ theme }) => theme.fontSizes.medium};
  cursor: pointer;
`;

const PreventBodyScrollStyle = createGlobalStyle`
    body {
        overflow: hidden;
    }
`;
