import { useMemo } from 'react';
import type { Column } from '../../Table/Table';
import { formatNumberWithCommas } from '../../../lib/numbers/formatNumberWithComma';
import { Icon, IconType } from '../../Props/Icon/Icon';
import styled from 'styled-components';
import type { LabelledToggleOption } from '../../Toggle/LabelledToggle/LabelledToggle';
import type { LeaderboardUser } from '../../../lib/types/LeaderboardUser';
import { UserRank } from '../components';
import { NamedContentEntryText } from '../../ContentEntryText/NamedContentEntryText';
import { AvatarInitials } from '../../AvatarInitials';

type LeaderboardWidgetData = {
  columns: Column<LeaderboardUser>[];
  scopeFilterLabels: LabelledToggleOption[];
  dateFilterLabels: LabelledToggleOption[];
};

export const useLeaderboardWidgetData = (isManager: boolean): LeaderboardWidgetData => {
  return useMemo(
    () => ({
      scopeFilterLabels: [
        {
          label: <NamedContentEntryText container="span" refKey="leaderboard" subKey="myTeam" />,
          value: 'team',
        },
        // TODO: Change to all departments filter when this functionality is implemented on BE
        {
          label: (
            <NamedContentEntryText
              container="span"
              refKey="leaderboard"
              subKey={isManager ? 'departments' : 'myDepartment'}
            />
          ),
          value: 'department',
        },
        {
          label: <NamedContentEntryText container="span" refKey="leaderboard" subKey="myCompany" />,
          value: 'company',
          selected: true,
        },
      ],
      dateFilterLabels: [
        {
          label: <NamedContentEntryText container="span" refKey="leaderboard" subKey="allTime" />,
          selected: true,
          value: 'all',
        },
        {
          label: <NamedContentEntryText container="span" refKey="leaderboard" subKey="thisMonth" />,
          value: 'month',
        },
      ],
      columns: [
        {
          header: 'Rank',
          valueSelector: (user: LeaderboardUser) => <UserRank rank={user.rank} previousRank={user.previousRank} />,
          alignment: 'left',
          width: '85px',
        },
        {
          header: 'Name',
          valueSelector: (user: LeaderboardUser) => (
            <UserName>
              {user.avatar ? (
                <UserImg src={`data:image/png;base64,${user.avatar}`} />
              ) : (
                <AvatarInitials name={user.name || ''} id={user.name ?? ''} size="32px" />
              )}
              {user.name}
            </UserName>
          ),
          alignment: 'left',
          width: '1fr',
        },
        {
          header: 'Points',
          valueSelector: (user: LeaderboardUser) => (
            <UserPoints>
              {formatNumberWithCommas(user.cyberIqPoints)} <Icon width={20} height={20} icon={IconType.COIN} />
            </UserPoints>
          ),
          alignment: 'right',
          width: '100px',
        },
      ],
    }),
    [isManager],
  );
};

const UserPoints = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.medium};
  display: flex;
  align-items: center;
  gap: 8px;
`;

const UserImg = styled.img`
  border-radius: 50%;
  width: 32px;
  height: 32px;
  cursor: pointer;
`;

const UserName = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: ${({ theme }) => theme.fontSizes.medium};
`;
