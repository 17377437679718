import styled from 'styled-components';
import { LeaderboardWidgetUI } from '../Leaderboard/LeaderboardWidgetUI';
import { SecuritySnapshotUI } from '../SecuritySnapshot/SecuritySnapshotUI';
import { TrainingWidget } from '../Activity/TrainingWidget';
import { ScoreBreakdown } from '../ScoreBreakdown/ScoreBreakdown';
import { withErrorBoundaries } from '../../lib/decorators/withErrorBoundaries';
import { CardErrorView } from '../ErrorView/CardErrorView';

const {
  LeaderboardWidgetUI_WithErrorBoundary,
  ScoreBreakdown_WithErrorBoundary,
  SecuritySnapshotUI_WithErrorBoundary,
  TrainingWidget_WithErrorBoundary,
} = withErrorBoundaries(
  {
    LeaderboardWidgetUI,
    SecuritySnapshotUI,
    TrainingWidget,
    ScoreBreakdown,
  },
  CardErrorView,
);

export const Overview: React.FC = () => {
  return (
    <Layout>
      <Column>
        <SecuritySnapshotUI_WithErrorBoundary />
        <ScoreBreakdown_WithErrorBoundary />
      </Column>
      <Column>
        <LeaderboardWidgetUI_WithErrorBoundary />
        <TrainingWidget_WithErrorBoundary />
      </Column>
    </Layout>
  );
};

const Layout = styled.div`
  display: grid;
  gap: 16px;
  grid-template-columns: 3fr 2fr;
  min-height: 100%;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
