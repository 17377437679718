import React, { useCallback } from 'react';
import { useKey } from './useKey';

export function useEscapeKeyHandler({ active, onEscapePressed }: UseEscapeKeyHandlerProps): void {
  const keyPred = React.useCallback((key: string) => ['Esc', 'Escape'].includes(key), []);
  const keyPressCallback = useCallback(() => {
    if (active) {
      onEscapePressed();
    }
  }, [active, onEscapePressed]);
  useKey(keyPred, keyPressCallback);
}
type UseEscapeKeyHandlerProps = {
  active: boolean;
  onEscapePressed: () => void;
};
