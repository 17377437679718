import styled from 'styled-components';

export type ErrorViewProps = {
  error: unknown;
  onTryAgain?: () => void;
};

export const ErrorView: React.FC<ErrorViewProps> = ({ error, onTryAgain }) => {
  return (
    <Container role="alert" dir="ltr">
      <div style={{ maxWidth: '50em' }}>
        <h1>Oops!</h1>
        <p>Sorry, an unexpected error has occurred.</p>
        <p className="msg">
          {error != null &&
            typeof error === 'object' &&
            'message' in error &&
            error.message != null &&
            typeof error.message === 'string' && <p>{error.message}</p>}
        </p>
        {onTryAgain && (
          <p>
            <a style={{ cursor: 'pointer' }} onClick={() => onTryAgain()}>
              Try again
            </a>
          </p>
        )}
      </div>
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  place-items: center;
  min-height: 100%;
  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1em;
    > .msg {
      margin-top: 1em;
      font-weight: 600;
    }
  }
  text-align: center;
  padding: 8px;
`;
