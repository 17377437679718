import type { FC } from 'react';
import { TrainingWidget } from './TrainingWidget';
import styled from 'styled-components';
import { ActivityOverviewWidget } from './ActivityOverviewWidget';
import { withErrorBoundaries } from '../../lib/decorators/withErrorBoundaries';
import { CardErrorView } from '../ErrorView/CardErrorView';

const { ActivityOverviewWidget_WithErrorBoundary, TrainingWidget_WithErrorBoundary } = withErrorBoundaries(
  {
    ActivityOverviewWidget,
    TrainingWidget,
  },
  CardErrorView,
);

export const Activity: FC = () => {
  return (
    <Layout>
      <Column>
        <TrainingWidget_WithErrorBoundary />
      </Column>
      <Column>
        <ActivityOverviewWidget_WithErrorBoundary />
      </Column>
    </Layout>
  );
};

const Layout = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
  min-height: 100%;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
