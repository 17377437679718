import { LabelledToggle } from '../Toggle';
import { Table } from '../Table';
import styled, { useTheme } from 'styled-components';
import type { LeaderboardUser } from '../../lib/types/LeaderboardUser';
import { type LeaderboardPagedResults, useLeaderboardQuery } from '../../apiHooks/useLeaderboardQuery';
import { useLeaderboardWidgetData } from './hooks/useLeaderboardWidgetData';
import { useEffect, useState, type FC } from 'react';
import { Card } from '../Card';
import { NamedContentEntryText } from '../ContentEntryText/NamedContentEntryText';
import { useMeQuery } from '../../apiHooks/useMeQuery';
import { NoLeaderboardDataCard } from './components/NoLeaderboardDataCard';

type LeaderboardWidgetUIProps = {
  isManager?: boolean;
};

export const LeaderboardWidgetUI: FC<LeaderboardWidgetUIProps> = ({ isManager = false }) => {
  const { columns, scopeFilterLabels } = useLeaderboardWidgetData(isManager);
  const [selectedScopeFilter, setSelectedScopeFilter] = useState<string | undefined>(scopeFilterLabels[0]?.value);
  const theme = useTheme();

  const { data: meData } = useMeQuery();
  const {
    data: leaderboardWidgetData,
    isFetching,
    refetch,
  } = useLeaderboardQuery({
    type: selectedScopeFilter || 'company',
    mode: 'top',
    count: 5,
  });

  useEffect(() => {
    refetch();
  }, [selectedScopeFilter, refetch]);

  const getTableData = (data: LeaderboardUser[] | LeaderboardPagedResults | undefined): LeaderboardUser[] => {
    if (Array.isArray(data)) {
      return data;
    }
    return data?.values || [];
  };

  const tableData = getTableData(leaderboardWidgetData);

  const onScopeFilterChange = (index: number) => {
    console.log(`Selected scope: ${scopeFilterLabels[index]?.value}`);
    setSelectedScopeFilter(scopeFilterLabels[index]?.value);
  };

  // const onDateFilterChange = (index: number) => {
  //   console.log(`Selected date: ${dateFilterLabels[index]?.value}`);
  // };

  return (
    <Card title={<NamedContentEntryText container={'span'} refKey="sectionTitles" subKey="leaderboard" />}>
      <FiltersBar>
        <LabelledToggle
          labels={scopeFilterLabels}
          onSelect={(selectedIndex: number) => onScopeFilterChange(selectedIndex)}
        />
        {/*<LabelledToggle*/}
        {/*  labels={dateFilterLabels}*/}
        {/*  onSelect={(selectedIndex: number) => onDateFilterChange(selectedIndex)}*/}
        {/*/>*/}
      </FiltersBar>
      <Table
        data={tableData}
        columns={columns}
        hideHeaders
        roundedRows
        hideSeparator
        rowColorSelector={(data: LeaderboardUser) =>
          data.learnerId === meData?.learnerId ? theme.colors.pageBackground : 'unset'
        }
        renderZeroState={() => <NoLeaderboardDataCard />}
        isLoading={isFetching}
      />
    </Card>
  );
};

const FiltersBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
