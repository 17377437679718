import { type FC } from 'react';
import { UserImg, type TopBarUIUser } from '../TopBarUI';
import { AvatarInitials } from '../../AvatarInitials';

export const UserAvatar: FC<UserAvatarProps> = ({ user }) => {
  return user.avatar ? (
    <UserImg src={`data:image/png;base64,${user.avatar}`} alt="User Avatar" />
  ) : (
    <AvatarInitials name={user.name || ''} id={user.name ?? ''} size="40px" />
  );
};

export type UserAvatarProps = {
  user: TopBarUIUser;
};
