import { useZustandStore } from '../appState/StoresProvider';
import { useLPQuery } from '../lib/hooks/useLPQuery';

export function useMeQuery() {
  const isLoggedIn = useZustandStore('auth', (v) => v.authenticationState === 'loggedIn');
  return useLPQuery<Me>(['me'], '/me', { enabled: isLoggedIn });
}
type Me = {
  learnerId: string;
  name?: string | null;
  portalRole: string;
  authOption?: string | null;
  languageCode?: string | null;
  country?: string | null;
};
