import type React from 'react';
import { TopBarUI } from './TopBarUI';
import { useTopBarUIProps } from './hooks/useTopBarUIProps';
import type { MenuPage } from '../../topBarMenuPages/MenuPage';

export const TopBar: React.FC<{
  topBarMenuPages: MenuPage[];
  managerView?: boolean;
}> = ({ topBarMenuPages, managerView }) => {
  const props = useTopBarUIProps(topBarMenuPages, managerView);
  return <TopBarUI {...props} />;
};
