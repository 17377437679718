import { type ContentEntryRef } from '../../types/ContentEntryRef';
import { useContentEntriesBatcher } from './useContentEntriesBatcher';
import { useMemo } from 'react';
import { createContentEntryQueryOptions } from '../createContentEntryQueryOptions';
import type { ContentEntryData } from '../../types/ContentEntryResponse';
import type { Optional } from '../../../../types/Optional';

export function useContentEntryQueryOptions<T extends ContentEntryData>(
  ref?: Optional<ContentEntryRef<T>, 'contentId' | 'moduleId'>,
) {
  const batcher = useContentEntriesBatcher();
  return useMemo(() => createContentEntryQueryOptions(ref, batcher), [ref, batcher]);
}
