import type { SnapshotLevel } from '../contentEntryRefs/contentEntryRefs';
import { useLPSuspenseQuery } from '../lib/hooks/useLPQuery';

export function useTeamCyberIQLevelsQuery() {
  // TODO: use team's IQ levels query once ready from BE
  return useLPSuspenseQuery<TeamCyberIqLevels>(['cyber-iq-levels'], '/cyberIq/levels');
}
export type TeamCyberIqLevels = TeamCyberIQLevel[];

export type TeamCyberIQLevel = {
  id: SnapshotLevel;
  pointsThreshold: number;
  assetUrl: string;
};
