import styled from 'styled-components';
import { type FC, useMemo, useState } from 'react';
import { Icon, IconType } from '../Props/Icon/Icon';
import { Card } from '../Card';
import { Coin } from '../Props';
import { NamedContentEntryText } from '../ContentEntryText/NamedContentEntryText';
import { type ScoreItem, ScoreItemIdentifier, useScoreBreakdownsQuery } from '../../apiHooks/useScoreBreakdownsQuery';
import {
  type CampaignAction,
  CampaignActionType,
  type ScoreData,
  useScoreBreakdownByUserQuery,
} from '../../apiHooks/useScoreBreakdownByUserQuery';
import { StatusIcon, StatusIconType } from '../Props/StatusIcon/StatusIcon';
import { NoUserDataCard } from './components/NoUserDataCard';
import { Tooltip } from '../Tooltip';
import { ActionTooltip } from './components/ActionTooltip';

const computeActionScore = (scoreItem: ScoreItem, scoreBreakdowns: ScoreData['scoreBreakdowns']): number => {
  const statusIds: number[] = [scoreItem.success, scoreItem.fail, scoreItem.neutral];

  return scoreBreakdowns.reduce((accumulator, scoreBreakdown) => {
    if (statusIds.includes(scoreBreakdown.actionScoreType)) {
      return accumulator + scoreBreakdown.score;
    }

    return accumulator;
  }, 0);
};

export const ScoreBreakdown: FC = () => {
  const scoreBreakdownsQuery = useScoreBreakdownsQuery();
  const scoreBreakdownByUserQuery = useScoreBreakdownByUserQuery();
  const [expandedIndices, setExpandedIndices] = useState<number[]>([]);

  const trainings = (scoreBreakdownsQuery.data ?? [])?.map((item) => ({
    title: item.name,
    description: item.description,
    assetUrl: item.assetUrl,
    coins: computeActionScore(item, scoreBreakdownByUserQuery.data?.scoreBreakdowns ?? []),
    identifier: item.identifier,
    titleKey: `scoreBreakdown${item.identifier}` as const,
  }));

  const filteredTrainings = useMemo(() => {
    return trainings.filter((training) =>
      scoreBreakdownByUserQuery.data.scoreBreakdowns.some((score) => score.identifier === training.identifier),
    );
  }, [trainings, scoreBreakdownByUserQuery]);

  const actions: CampaignAction[] = scoreBreakdownByUserQuery.data?.campaignActions ?? [];

  const toggleExpand = (index: number) => {
    setExpandedIndices((prev) => (prev.includes(index) ? prev.filter((i) => i !== index) : [...prev, index]));
  };

  return (
    <Container>
      <Card title={<NamedContentEntryText container={'span'} refKey="sectionTitles" subKey="scoreBreakdown" />}>
        {!!scoreBreakdownByUserQuery.data?.scoreBreakdowns.length &&
          filteredTrainings.map((item, index) => {
            const shouldShowActions =
              item.identifier === ScoreItemIdentifier.PHISHING_CLICKS ||
              item.identifier === ScoreItemIdentifier.PHISHING_REPORTS;

            return (
              <ScoreItem key={item.title}>
                <ScoreItemIcon>
                  <img src={item.assetUrl} alt={item.title} />
                </ScoreItemIcon>
                <ScoreItemContent>
                  <ScoreItemHeader onClick={() => toggleExpand(index)}>
                    <ToggleArrow>
                      <Icon
                        icon={expandedIndices.includes(index) ? IconType.CHEVRON_DOWN : IconType.CHEVRON_RIGHT}
                        fill={'transparent'}
                      />
                    </ToggleArrow>
                    <ScoreItemHeaderTitle>
                      <NamedContentEntryText refKey={item.titleKey} container={'span'} subKey={'heading'} />
                    </ScoreItemHeaderTitle>
                  </ScoreItemHeader>
                  <ScoreItemDescription $isVisible={expandedIndices.includes(index)}>
                    <NamedContentEntryText refKey={item.titleKey} container={'span'} subKey={'body'} />
                    {actions.length > 0 && shouldShowActions && (
                      <ScoreItemFooter>
                        <Icon className="rtl-flip" icon={IconType.CORNER_DOWN_RIGHT_ARROW} height={16} width={16} />
                        <ScoreItemPillsContainer>
                          <ScoreItemPillsIconsContainer>
                            {actions.map((action) => (
                              <Tooltip
                                content={<ActionTooltip actionResult={action.result} date={action.date} />}
                                key={action.campaignId}
                              >
                                <StatusIcon
                                  icon={
                                    action.result === CampaignActionType.SUCCESS
                                      ? StatusIconType.SUCCESS
                                      : action.result === CampaignActionType.NEUTRAL
                                        ? StatusIconType.NEUTRAL
                                        : StatusIconType.FAIL
                                  }
                                  height={16}
                                  width={16}
                                />
                              </Tooltip>
                            ))}
                          </ScoreItemPillsIconsContainer>
                          <NamedContentEntryText
                            refKey={'scoreBreakdownTooltips'}
                            container={'span'}
                            subKey={'result'}
                          />
                        </ScoreItemPillsContainer>
                      </ScoreItemFooter>
                    )}
                  </ScoreItemDescription>
                </ScoreItemContent>
                <ScoreItemCoin>{item.coins ? <Coin value={item.coins} /> : null}</ScoreItemCoin>
              </ScoreItem>
            );
          })}
        {!scoreBreakdownByUserQuery.data?.scoreBreakdowns.length && <NoUserDataCard />}
      </Card>
    </Container>
  );
};

const Container = styled.div`
  display: block;
`;

const ScoreItem = styled.div`
  display: flex;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    gap: 16px;
  }
`;

const ScoreItemIcon = styled.div`
  display: flex;
  width: 64px;
  height: 64px;
  max-width: 64px;
  max-height: 64px;
  background: #f7f8fa;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  flex-grow: 0;
  flex-shrink: 0;

  @media (max-width: 768px) {
    width: 32px;
    height: 32px;

    img {
      width: 32px;
      height: 32px;
    }
  }
`;

const ScoreItemContent = styled.div`
  width: 100%;
  margin: 0 20px;

  @media (max-width: 768px) {
    margin: 0;
  }
`;

const ScoreItemHeader = styled.div`
  display: flex;
`;

const ScoreItemHeaderTitle = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.medium};
  color: ${({ theme }) => theme.colors.heading};
  font-weight: 600;
  letter-spacing: 0.28px;
  margin-right: 20px;

  @media (max-width: 768px) {
    margin-right: 10px;
  }
`;

const ScoreItemCoin = styled.div`
  display: flex;
  align-items: baseline;
`;

const ToggleArrow = styled.div`
  cursor: pointer;
  display: none;

  @media (max-width: 768px) {
    display: block;
    margin-right: 16px;
  }
`;

const ScoreItemDescription = styled.div<{ $isVisible: boolean }>`
  margin-top: 8px;
  font-size: ${({ theme }) => theme.fontSizes.normal};

  @media (max-width: 768px) {
    display: ${({ $isVisible }) => ($isVisible ? 'block' : 'none')};
  }
`;

const ScoreItemFooter = styled.div`
  margin-top: 4px;
  display: flex;
  justify-content: flex-start;
  gap: 12px;
  align-items: center;
`;

const ScoreItemPillsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  border: 1px solid #d5daee;
  background: #eef1f9;
  border-radius: 16px;
  padding: 4px 8px 4px 4px;
  gap: 6px;
  align-items: center;
  font-size: ${({ theme }) => theme.fontSizes.small};
  font-weight: 500;
  /* This margin is to align the pills with the icon */
  margin-top: 4px;
`;

const ScoreItemPillsIconsContainer = styled.div`
  display: flex;
  gap: 2px;
`;
