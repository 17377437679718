import { useLPSuspenseQuery } from '../lib/hooks/useLPQuery';
import { useEffect } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useLPSuspenseQueryOpts } from '../lib/hooks/useLPQuery';

const languagesQueryKey = ['languages'];
const languagesEndpointPath = '/languages';
const qOpts = {
  staleTime: 86400000,
};

const args = [languagesQueryKey, languagesEndpointPath, qOpts] as const;

export function useLanguagesQuery() {
  return useLPSuspenseQuery<LanguagesQueryResponse>(...args);
}

export function usePrefetchLanguages() {
  const queryClient = useQueryClient();
  const queryOpts = useLPSuspenseQueryOpts(...args);
  useEffect(() => {
    queryClient.prefetchQuery(queryOpts);
  }, [queryOpts, queryClient]);
}

type Language = {
  code: string;
  name: string;
};

type LanguagesQueryResponse = Language[];
