import styled from 'styled-components';
import { Card } from '../../Card';
import { type FC } from 'react';
import { Icon, IconType } from '../../Props/Icon/Icon';
import { NamedContentEntryText } from '../../ContentEntryText/NamedContentEntryText';

export const NoLeaderboardDataCard: FC = () => {
  return (
    <CardContainer>
      <Icon icon={IconType.STAR_MEDAL} width={24} height={24} fill="transparent" />
      <DescriptionText>
        <NamedContentEntryText container="span" refKey="table" subKey="noItems" />
      </DescriptionText>
    </CardContainer>
  );
};

const CardContainer = styled(Card)`
  background-color: ${({ theme }) => theme.colors.trainingCardBackground};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  text-align: center;
  box-shadow: unset;
`;

const DescriptionText = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.normal};
  color: ${({ theme }) => theme.colors.normal};
  font-weight: 400;
`;
