import type { PropsWithChildren, ReactHTML } from 'react';
import React from 'react';
import type {} from '../../lib/hooks/contentEntry/types/ContentEntryRefWithFallback';
import { useContentEntry } from '../../lib/hooks/contentEntry/util/hooks/useContentEntry';
import type { ContentEntryTextBlockRefWithFallback } from '../../contentEntryRefs/util/toContentEntryRefs';
import { toSanitizedDOM } from '../../lib/html/toSanitizedDOM';
import { useShouldUseContentEntryFallbacks } from '../../lib/hooks/contentEntry/useShouldUseContentEntryFallbacks';
import styled from 'styled-components';
import { usePropertyTransition } from './usePropertyTransition';

export type ContentEntryTextProps<
  PD extends Record<string, number>,
  T extends ContentEntryTextBlockRefWithFallback<PD>,
> = {
  asHtml?: boolean;
  container: React.ComponentType<PropsWithChildren> | keyof ReactHTML;
  contentEntryRef: T;
} & (T extends { nested: true; propSubKeyIndexes: infer P } ? { subKey: keyof P } : object);

export const ContentEntryText = <PD extends Record<string, number>, T extends ContentEntryTextBlockRefWithFallback<PD>>(
  props: ContentEntryTextProps<PD, T>,
): JSX.Element => {
  const [isPending, ceref] = usePropertyTransition(props.contentEntryRef);
  const content = useContentEntry(ceref);
  const shouldUseContentEntryFallbacks = useShouldUseContentEntryFallbacks();

  if (content.missing && !shouldUseContentEntryFallbacks) {
    return (
      <span style={{ fontSize: '8px', color: 'white', backgroundColor: 'red', outline: '1px solid red' }}>
        {content.ref?.contentId ? <>{content.ref?.contentId} not found</> : <>Text has no contentId</>}
      </span>
    );
  }
  return (
    <>
      {content.entry?.data.text
        .filter((_, idx) =>
          'subKey' in props && 'propSubKeyIndexes' in ceref ? ceref.propSubKeyIndexes[props.subKey] === idx : true,
        )
        .map((text, index) => {
          return (
            <props.container key={index}>
              {props.asHtml ? (
                <HTMLSpan blur={isPending} html={text} />
              ) : (
                <BlurSpan $applyBlur={isPending}>{text}</BlurSpan>
              )}
            </props.container>
          );
        })}
    </>
  );
};

const BlurSpan = styled.span<{ $applyBlur: boolean }>`
  transition:
    filter 0.3s,
    opacity 0.3s;
  filter: ${({ $applyBlur }) => ($applyBlur ? 'blur(4px)' : 'none')};
  opacity: ${({ $applyBlur }) => ($applyBlur ? 0.6 : 1)};
`;

type HTMLRendererProps = {
  html: string;
  blur: boolean;
};

function HTMLSpan({ html, blur }: HTMLRendererProps): React.ReactNode {
  const sanitizedHTML = useSanitizedHTML(html);
  return <BlurSpan $applyBlur={blur} dangerouslySetInnerHTML={{ __html: sanitizedHTML }} />;
}

function useSanitizedHTML(html: string) {
  return React.useMemo(() => {
    const sanitized = toSanitizedDOM(html);
    const anchors = sanitized.querySelectorAll('a');
    anchors.forEach((e) => {
      e.setAttribute('target', '_blank');
      e.setAttribute('rel', 'noopener');
    });
    return sanitized.innerHTML;
  }, [html]);
}
