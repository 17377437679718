import { useMemo } from 'react';
import type { Column } from '../../Table/Table';
import { UserRank } from '../components';
import { UserLevelDetails } from '../../../lib/enums/userLevels';
import { formatNumberWithCommas } from '../../../lib/numbers/formatNumberWithComma';
import { Icon, IconType } from '../../Props/Icon/Icon';
import styled from 'styled-components';
import type { LabelledToggleOption } from '../../Toggle/LabelledToggle/LabelledToggle';
import { BadgeList } from '../../Props/BadgeList/BadgeList';
import { Tooltip } from '../../Tooltip';
import { LevelTooltip } from '../components';
import type { LeaderboardUser } from '../../../lib/types/LeaderboardUser';
import { NamedContentEntryText } from '../../ContentEntryText/NamedContentEntryText';
import { AvatarInitials } from '../../AvatarInitials';
import { useMeQuery } from '../../../apiHooks/useMeQuery';
import ExternalLinkButton from '../../Button/ExternalLinkButton/ExternalLinkButton';

type LeaderboardData = {
  columns: Column<LeaderboardUser>[];
  mobileColumns: Column<LeaderboardUser>[];
  scopeFilterLabels: LabelledToggleOption[];
  dateFilterLabels: LabelledToggleOption[];
};

export const useLeaderboardData = (): LeaderboardData => {
  const { data: meData } = useMeQuery();

  return useMemo(
    () => ({
      scopeFilterLabels: [
        {
          label: <NamedContentEntryText container="span" refKey="leaderboard" subKey="myTeam" />,
          value: 'team',
        },
        {
          label: <NamedContentEntryText container="span" refKey="leaderboard" subKey="myDepartment" />,
          value: 'department',
        },
        {
          label: <NamedContentEntryText container="span" refKey="leaderboard" subKey="myCompany" />,
          value: 'company',
          selected: true,
        },
      ],
      dateFilterLabels: [
        {
          label: <NamedContentEntryText container="span" refKey="leaderboard" subKey="allTime" />,
          selected: true,
          value: 'all',
        },
        {
          label: <NamedContentEntryText container="span" refKey="leaderboard" subKey="thisMonth" />,
          value: 'month',
        },
      ],
      columns: [
        {
          header: <NamedContentEntryText container={'span'} refKey="leaderboard" subKey="rank" />,
          valueSelector: (user: LeaderboardUser) => <UserRank rank={user.rank} previousRank={user.previousRank} />,
          alignment: 'left',
          width: '1fr',
        },
        {
          header: <NamedContentEntryText container={'span'} refKey="leaderboard" subKey="name" />,
          valueSelector: (user: LeaderboardUser) => (
            <UserName>
              {user.avatar ? (
                <UserImg src={`data:image/png;base64,${user.avatar}`} />
              ) : (
                <AvatarInitials name={user.name || ''} id={user.name ?? ''} size="32px" />
              )}
              {user.name}
            </UserName>
          ),
          alignment: 'left',
          width: '2fr',
        },
        {
          header: <NamedContentEntryText container={'span'} refKey="leaderboard" subKey="badges" />,
          valueSelector: (user: LeaderboardUser) => (
            <BadgeList
              badgeIds={[
                IconType.BADGE_GLOBE_TROTTER,
                IconType.BADGE_PHANTOM_POSTER,
                IconType.BADGE_RULER_OF_MASSES,
                IconType.BADGE_THE_TALKINATOR,
              ]}
              renderLinks={() => (
                <ExternalLinksContainer>
                  {!!user.badges?.length && <ExternalLinkButton href="/" text="View all" className="view-all" />}
                  {user.rank > 10 && user.learnerId === meData?.learnerId ? (
                    <ExternalLinkButton href="/" text="How to earn more" className="earn-badges" />
                  ) : null}
                </ExternalLinksContainer>
              )}
            />
          ),
          alignment: 'left',
          width: '6fr',
        },
        {
          header: <NamedContentEntryText container={'span'} refKey="leaderboard" subKey="level" />,
          valueSelector: (user: LeaderboardUser) => (
            <UserLevelWrapper>
              <Tooltip offset={15} content={<LevelTooltip tooltipDetails={UserLevelDetails[user.levelId]} />}>
                <UserLevel>
                  {UserLevelDetails[user.levelId]?.level} <b>{UserLevelDetails[user.levelId]?.name}</b>
                </UserLevel>
              </Tooltip>
            </UserLevelWrapper>
          ),
          alignment: 'left',
          width: '200px',
        },
        {
          header: <NamedContentEntryText container={'span'} refKey="leaderboard" subKey="points" />,
          valueSelector: (user: LeaderboardUser) => (
            <UserPoints className="user-points">
              {formatNumberWithCommas(user.cyberIqPoints)} <Icon width={20} height={20} icon={IconType.COIN} />
            </UserPoints>
          ),
          alignment: 'right',
          width: '150px',
        },
      ],
      mobileColumns: [
        {
          header: <NamedContentEntryText container={'span'} refKey="leaderboard" subKey="rank" />,
          valueSelector: (user: LeaderboardUser) => <UserRank rank={user.rank} previousRank={user.previousRank} />,
          alignment: 'left',
          width: '1fr',
        },
        {
          header: <NamedContentEntryText container={'span'} refKey="leaderboard" subKey="name" />,
          valueSelector: (user: LeaderboardUser) => (
            <Column>
              <UserName>
                {user.avatar ? (
                  <UserImg src={`data:image/png;base64,${user.avatar}`} />
                ) : (
                  <AvatarInitials name={user.name || ''} id={user.name ?? ''} size="36px" />
                )}
                {user.name}
              </UserName>
              <UserPoints>
                {formatNumberWithCommas(user.cyberIqPoints)} <Icon width={20} height={20} icon={IconType.COIN} />
              </UserPoints>
            </Column>
          ),
          alignment: 'left',
          width: '2fr',
        },
        {
          header: <NamedContentEntryText container={'span'} refKey="leaderboard" subKey="level" />,
          valueSelector: (user: LeaderboardUser) => (
            <Column>
              <UserLevel>
                <b>{UserLevelDetails[user.levelId]?.name}</b>{' '}
                <LevelNumber>{UserLevelDetails[user.levelId]?.number}</LevelNumber>
              </UserLevel>
              <BadgeList
                badgeIds={[
                  IconType.BADGE_GLOBE_TROTTER,
                  IconType.BADGE_PHANTOM_POSTER,
                  IconType.BADGE_RULER_OF_MASSES,
                  IconType.BADGE_THE_TALKINATOR,
                ]}
              />
            </Column>
          ),
          alignment: 'right',
          width: '2fr',
        },
      ],
    }),
    [meData],
  );
};

const UserLevelWrapper = styled.div`
  width: 100%;
  height: calc(100% - 10px);
  display: flex;
  align-items: center;
  border-right: 1px solid ${({ theme }) => theme.colors.tableBorder};

  @media (max-width: 768px) {
    border: none;
  }
`;

const UserLevel = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: ${({ theme }) => theme.fontSizes.normal};
  color: ${({ theme }) => theme.colors.userLevel};

  > b {
    text-transform: uppercase;
  }

  @media (max-width: 768px) {
    font-size: ${({ theme }) => theme.fontSizes.medium};
    padding-top: 8px;
  }
`;

const LevelNumber = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.userLevel};
  color: ${({ theme }) => theme.colors.primaryContrast};
`;

const UserPoints = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.medium};
  display: flex;
  align-items: center;
  gap: 8px;

  @media (max-width: 768px) {
    flex-direction: row-reverse;
    padding: 10px 8px;
    width: 90px;
    justify-content: flex-end;
    border-radius: ${({ theme }) => theme.borderRadius.extraLarge};
    background: ${({ theme }) => theme.colors.trainingCardBackground};
  }
`;

const UserImg = styled.img`
  border-radius: 50%;
  width: 32px;
  height: 32px;
  cursor: pointer;
`;

const UserName = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: ${({ theme }) => theme.fontSizes.medium};
  font-weight: 500;
  color: ${({ theme }) => theme.colors.heading};
`;

const Column = styled.div`
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 8px;
`;

const ExternalLinksContainer = styled.div`
  display: flex;
  gap: 8px;
  margin-left: auto;
`;
