import { useReducer, useEffect } from 'react';

export function useSecretCodeToggle(typedString: string) {
  const [isCheatCodeTyped, toggleIsCheatCodeTyped] = useReducer((s) => !s, false);
  useEffect(() => {
    let ongoingAttempts: string[] = [];
    const handleKeyPress = (event: KeyboardEvent) => {
      ongoingAttempts = ['', ...ongoingAttempts].map((v) => `${v}${event.key}`);
      ongoingAttempts.length = Math.min(ongoingAttempts.length, typedString.length);
      if (ongoingAttempts[ongoingAttempts.length - 1] === typedString) {
        toggleIsCheatCodeTyped();
        ongoingAttempts = [];
      }
    };
    document.addEventListener('keypress', handleKeyPress);
    return () => {
      document.removeEventListener('keypress', handleKeyPress);
    };
  }, [typedString]);
  return isCheatCodeTyped;
}
