import styled from 'styled-components';
import { type FC } from 'react';
import {
  SteppingProgressBar,
  type SteppingProgressBarStep,
} from '../ProgressBar/SteppingProgressBar/SteppingProgressBar';
import { Icon, IconType } from '../Props/Icon/Icon';
import { useMeQuery } from '../../apiHooks/useMeQuery';
import { Card } from '../Card';
import { ProgressBar } from '../ProgressBar/ProgressBar';
import { useTeamCyberIQLevelsQuery } from '../../apiHooks/useTeamCyberIQLevelsQuery';
import { useTeamSecuritySnapshotQuery } from '../../apiHooks/useTeamSecuritySnapshotQuery';
import { Tooltip } from '../Tooltip';
import { NamedContentEntryText } from '../ContentEntryText/NamedContentEntryText';

export const TeamSecuritySnapshotUI: FC = () => {
  let currentIQPoints = '';
  let currentThresholdIQPoints = '';
  let currentLevel = 0;
  let lebelStepperSteps: SteppingProgressBarStep[] = [];

  const securitySnapshotQuery = useTeamSecuritySnapshotQuery();
  const cyberIqLevelsQuery = useTeamCyberIQLevelsQuery();
  const meQuery = useMeQuery();

  if (securitySnapshotQuery.data && meQuery.data && cyberIqLevelsQuery.data) {
    const securitySnapshotData = securitySnapshotQuery.data;
    const cyberIqLevelsData = cyberIqLevelsQuery.data;

    const currentLevelIndex: number = cyberIqLevelsData.findIndex((level) => {
      return level.id === securitySnapshotData.levelId;
    });

    const nextDataLevel = cyberIqLevelsData[currentLevelIndex + 1];

    if (nextDataLevel) {
      currentThresholdIQPoints = nextDataLevel.pointsThreshold.toLocaleString();
    }

    currentIQPoints = securitySnapshotData.cyberIqPoints.toLocaleString();

    currentLevel = currentLevelIndex;
    lebelStepperSteps = cyberIqLevelsData.map((level, index) => {
      return {
        value: index,
        label: level.id,
      };
    });
  }

  const Benchmark = (value?: string) => {
    return (
      <BenchmarkSection>
        <span>
          Benchmark org: <BenchmarkValue>{value || 'N/A'}</BenchmarkValue>
        </span>
      </BenchmarkSection>
    );
  };

  const TooltipInfo = (type: string) => {
    const getText = (type: string) => {
      if (type === 'score') {
        return (
          <NamedContentEntryText refKey="managerTeamSecuritySnapshot" subKey="cyberiqScoreTooltip" container="span" />
        );
      }
      if (type === 'level') {
        return (
          <NamedContentEntryText container={'span'} refKey="managerTeamSecuritySnapshot" subKey="cyberiqLevelTooltip" />
        );
      }
    };

    return <TooltipContainer>{getText(type)}</TooltipContainer>;
  };

  return (
    <Card title={<NamedContentEntryText container={'span'} refKey="managerSectionTitles" subKey="securitySnapshot" />}>
      <CardContainer>
        <CategoryContainer>
          <TitleContainer>
            <NamedContentEntryText container={'span'} refKey="managerTeamSecuritySnapshot" subKey="cyberiqScore" />
            <Tooltip offset={15} content={TooltipInfo('score')} placement="top">
              <Icon icon={IconType.INFO_TOOLTIP} width={16} height={16} fill="transparent" />
            </Tooltip>
          </TitleContainer>
          <SummaryContainer>
            <Section>
              <Icon icon={IconType.COIN} width={32} height={32} />
              <span>
                <SectionBoldValue>{currentIQPoints}</SectionBoldValue> / {currentThresholdIQPoints}
              </span>
            </Section>
            {Benchmark('2,440')}
          </SummaryContainer>
          <ProgressContainer>
            <ProgressBar steps={[]} value={currentLevel} showLabels={false} />
          </ProgressContainer>
        </CategoryContainer>
        <CategoryContainer>
          <TitleContainer>
            <NamedContentEntryText container={'span'} refKey="managerTeamSecuritySnapshot" subKey="cyberiqLevel" />
            <Tooltip offset={15} content={TooltipInfo('level')} placement="top">
              <Icon icon={IconType.INFO_TOOLTIP} width={16} height={16} fill="transparent" />
            </Tooltip>
          </TitleContainer>
          <SummaryContainer>
            <Section>
              <SectionBoldValue>{currentLevel}</SectionBoldValue>
            </Section>
            {Benchmark('Level 3')}
          </SummaryContainer>
          <ProgressContainer>
            <SteppingProgressBar showLabels={false} steps={lebelStepperSteps} value={currentLevel} />
          </ProgressContainer>
        </CategoryContainer>
      </CardContainer>
    </Card>
  );
};

const CardContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 32px;
  width: 100%;
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 16;
  }
`;

const CategoryContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 8px;
  width: 100%;
  @media (max-width: 768px) {
  }
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ProgressContainer = styled.div`
  grid-column: 1 / span 2;
  grid-row: 4;

  @media (max-width: 768px) {
    grid-column: 1 / span 2;
    grid-row: 5;
    margin-top: 10px;
  }
`;

const SummaryContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const Section = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  font-size: ${({ theme }) => theme.fontSizes.medium};
`;

const SectionBoldValue = styled.span`
  font-weight: 500;
  color: ${({ theme }) => theme.colors.strong};
  font-size: ${({ theme }) => theme.fontSizes.extraLarge};
`;

const BenchmarkSection = styled(Section)`
  font-size: ${({ theme }) => theme.fontSizes.small};
`;

const BenchmarkValue = styled.span`
  font-weight: 600;
`;

const TooltipContainer = styled.div`
  padding: 10px;
  width: 338px;
  background-color: ${({ theme }) => theme.colors.trainingCardBackground};
  border-radius: ${({ theme }) => theme.borderRadius.normal};
  box-shadow: #0c0c0d1a 2px 2px 8px;
`;
