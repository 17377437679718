import React from 'react';
import ExternalLinkIcon from '../../../assets/ExternalLinkIcon.svg';
import styled from 'styled-components';

export type ExternalLinkButtonProps = {
  href: string;
  text: string;
  className?: string;
};

const ExternalLinkButton: React.FC<ExternalLinkButtonProps> = ({ href, text, className }) => {
  return (
    <StyledLinkButton href={href} className={className}>
      <Content>
        <Text>{text}</Text>
        <ExternalLinkIcon />
      </Content>
    </StyledLinkButton>
  );
};

export default ExternalLinkButton;

const StyledLinkButton = styled.a`
  font-size: ${({ theme }) => theme.fontSizes.medium};
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 4px;
`;

const Text = styled.span`
  font-size: ${({ theme }) => theme.fontSizes.medium};
  white-space: nowrap;
  text-decoration: underline;
`;
