import styled from 'styled-components';
import { NamedContentEntryText } from '../../ContentEntryText/NamedContentEntryText';

export const User: React.FC<{ name: string | null; role: 'learner' | 'manager' | null }> = ({ name, role }) => {
  return (
    <UserContainer>
      <UserName>{name ?? ''}</UserName>

      <p className="role">
        {role && (
          <NamedContentEntryText
            container="span"
            refKey="menuSystem"
            subKey={role === 'learner' ? 'user' : 'manager'}
          />
        )}
      </p>
    </UserContainer>
  );
};
const UserName = styled.p`
  font-weight: bold;
  color: ${({ theme }) => theme.colors.heading};
`;
const UserContainer = styled.div`
  .role {
    text-transform: capitalize;
  }
`;
