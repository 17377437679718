import type { FC } from 'react';
import styled from 'styled-components';
import { darken } from 'polished';

type SplitButtonProps = {
  leftElement: React.ReactNode;
  rightElement: React.ReactNode;
  color?: string;
  onClick?: () => void;
};

export const SplitButton: FC<SplitButtonProps> = ({
  leftElement,
  rightElement,
  color = '#6e00ff',
  onClick,
}: SplitButtonProps) => {
  return (
    <ButtonContainer onClick={onClick}>
      <Level color={color}>{leftElement}</Level>
      <Label color={color}>{rightElement}</Label>
    </ButtonContainer>
  );
};

const ButtonContainer = styled.button`
  display: flex;
  align-items: center;
  height: fit-content;
  padding: 0;
  border: none;
  background: transparent;
  border-radius: ${({ theme }) => theme.borderRadius.normal};
  overflow: hidden;
  letter-spacing: 0.5px;
  font-size: 14px;
  font-weight: bold;
`;

const Level = styled.div<{
  color: string;
}>`
  white-space: nowrap;
  padding: 8px;
  border-radius: 8px 0 0 8px;
  color: ${({ theme }) => theme.colors.trainingCardBackground};
  background: ${({ color }) => color};
  font-size: 14px;
  font-weight: 500;
  text-align: center;

  @media (max-width: 768px) {
    padding: 7px 10px;
  }
`;

const Label = styled.div<{
  color: string;
}>`
  padding: 8px;
  border-radius: 0 8px 8px 0;
  color: ${({ theme }) => theme.colors.trainingCardBackground};
  background: ${({ color }) => darken(0.1, color)};
  font-size: 14px;
  font-weight: 500;
  text-align: center;

  @media (max-width: 768px) {
    padding: 7px 10px;
  }
`;
