import type { ComponentType, FC } from 'react';
import type { ErrorViewProps } from '../../components/ErrorView/ErrorView';
import { withErrorBoundary } from './withErrorBoundary';

const suffix = '_WithErrorBoundary';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function withErrorBoundaries<T extends Record<string, ComponentType<any>>>(
  components: T,
  ErrorComponent: FC<ErrorViewProps>,
): WithErrorBoundariesResult<T> {
  return Object.fromEntries(
    Object.entries(components).map(([key, value]) => [`${key}${suffix}`, withErrorBoundary(value, ErrorComponent)]),
  ) as WithErrorBoundariesResult<T>;
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type WithErrorBoundariesResult<T extends Record<string, ComponentType<any>>> = {
  [K in keyof T & string as `${K}${typeof suffix}`]: T[K];
};
