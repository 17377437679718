import { type FC, useCallback } from 'react';
import styled from 'styled-components';
import { Icon, IconType } from '../../Props/Icon/Icon';

type SettingsButtonProps = {
  isOpen: boolean;
  setSelectedMenu: (cb: (v: 'user' | 'switchView' | undefined) => 'user' | 'switchView' | undefined) => void;
};

export const SettingsButton: FC<SettingsButtonProps> = ({ isOpen, setSelectedMenu }: SettingsButtonProps) => {
  const clickHandler = useCallback(() => {
    setSelectedMenu((v) => (v === 'user' ? undefined : 'user'));
  }, [setSelectedMenu]);

  return (
    <SettingsContainer isOpen={isOpen} onClick={clickHandler}>
      <Icon className="settings-wheel" width={20} height={20} icon={IconType.SETTINGS_WHEEL} />
      <Icon width={16} height={16} icon={IconType.CHEVRON_DOWN} />
    </SettingsContainer>
  );
};

const SettingsContainer = styled.div.withConfig({
  shouldForwardProp: (prop) => prop !== 'isOpen',
})<{ isOpen: boolean }>`
  height: 32px;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  border: 1px solid ${({ theme }) => theme.colors.tableBorder};
  padding: 4px 8px;
  border-radius: ${({ theme }) => theme.borderRadius.extraLarge};

  .settings-wheel {
    transform: ${({ isOpen }) => (isOpen ? 'rotate(180deg)' : 'none')};
    transition: transform 500ms ease-in-out;
  }
`;
