import type { FC } from 'react';
import { Card } from '../Card';
import { ErrorView } from '.';
import type { ErrorViewProps } from './ErrorView';

export const CardErrorView: FC<ErrorViewProps> = (props) => (
  <Card title={<>An error occurred</>}>
    <div style={{ position: 'relative' }}>
      <ErrorView {...props} />
    </div>
  </Card>
);
