import { isContentEntryRefWithFallback } from '../../types/ContentEntryRefWithFallback';
import { type Optional } from '../../../../types/Optional';
import { type ContentEntryRef } from '../../types/ContentEntryRef';
import type { ContentEntryData, ContentEntryResponse } from '../../types/ContentEntryResponse';
import { useContentEntryQuery } from './useContentEntryQuery';
import { useShouldUseContentEntryFallbacks } from '../../useShouldUseContentEntryFallbacks';

export function useContentEntry<T extends ContentEntryData>(
  ref: Optional<ContentEntryRef<T>, 'contentId' | 'moduleId'> | undefined,
): ContentEntryResponse<T> {
  const useFallbacks = useShouldUseContentEntryFallbacks();
  const queryResult = useContentEntryQuery(ref);
  return {
    ref,
    entry:
      queryResult.data?.entry ??
      (ref != null && useFallbacks && isContentEntryRefWithFallback(ref)
        ? {
            contentId: ref.contentId,
            data: ref.fallbackData,
            type: ref.type,
            isOverride: false,
            languageCode: 'en-GB',
            metadata: {},
          }
        : undefined),
    missing: queryResult.isFetched && !queryResult.data?.entry,
  } as ContentEntryResponse<T>;
}
