import { useLPSuspenseQuery } from '../lib/hooks/useLPQuery';

export enum CampaignActionType {
  SUCCESS = 'success',
  NEUTRAL = 'neutral',
  FAIL = 'fail',
}

type ScoreBreakdown = {
  actionScoreType: number;
  score: number;
  scoreBreakdownId: number;
  createdTime: string;
  identifier: string;
  assetUrl: string;
};

export type CampaignAction = {
  campaignId: string;
  date: string;
  result: CampaignActionType;
};

export type ScoreData = {
  scoreBreakdowns: ScoreBreakdown[];
  campaignActions: CampaignAction[];
};

export function useScoreBreakdownByUserQuery() {
  return useLPSuspenseQuery<ScoreData>([`scoreBreakdowns/me`], `/scoreBreakdowns/me`);
}
