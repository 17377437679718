import { useSuspenseQuery } from '@tanstack/react-query';
import { type ContentEntryRef } from '../../types/ContentEntryRef';
import { useContentEntryQueryOptions } from './useContentEntryQueryOptions';
import type { ContentEntryData } from '../../types/ContentEntryResponse';
import type { Optional } from '../../../../types/Optional';

export function useContentEntryQuery<T extends ContentEntryData>(
  ref?: Optional<ContentEntryRef<T>, 'contentId' | 'moduleId'>,
) {
  const queryOptions = useContentEntryQueryOptions(ref);
  return useSuspenseQuery(queryOptions);
}
