import { useMemo } from 'react';
import styled from 'styled-components';
import type { ManagerTeamUser } from '../../../../../lib/types/ManagerTeamUser';
import { ProgressBar, ProgressBarContainer, ProgressFill, TrainingPrimaryButton } from '../../TrainingCard';
import type { Column } from '../../../../Table/Table';
import { NamedContentEntryText } from '../../../../ContentEntryText/NamedContentEntryText';
import { formatNumberWithCommas } from '../../../../../lib/numbers/formatNumberWithComma';
import { Icon, IconType } from '../../../../Props/Icon/Icon';
import { AvatarInitials } from '../../../../AvatarInitials';
import { getRankArrow } from '../../../../Leaderboard/components/UserRank/utils/getRankArrow';

type LeaderboardData = {
  columns: Column<ManagerTeamUser>[];
};

const onNudgeUser = (user: ManagerTeamUser) => {
  console.log('TOOD: Implement Nudge BE request', user);
};
const getProgressBarColor = (progress: number) => {
  if (progress < 50) {
    return '#E93354';
  } else if (progress < 75) {
    return '#FF6F5A';
  } else if (progress === 100) {
    return '#3ABF87';
  } else {
    return undefined;
  }
};
export const useTeamTrainingCompleationData = (): LeaderboardData => {
  return useMemo(
    () => ({
      columns: [
        {
          header: <NamedContentEntryText container={'span'} refKey="leaderboard" subKey="name" />,
          valueSelector: (user: ManagerTeamUser) => (
            <UserName>
              {user.avatar ? (
                <UserImg src={`data:image/png;base64,${user.avatar}`} />
              ) : (
                <AvatarInitials name={user.name || ''} id={user.name ?? ''} size="32px" />
              )}
              {user.name}
            </UserName>
          ),
          alignment: 'left',
          width: '1.5fr',
        },
        {
          // TODO: translation key
          header: 'Job Title',
          valueSelector: (user: ManagerTeamUser) => <UserPropTitle>{user.jobTitle}</UserPropTitle>,
        },
        {
          // TODO: translation key
          header: 'Department',
          valueSelector: (user: ManagerTeamUser) => <UserPropTitle>{user.department}</UserPropTitle>,
        },
        {
          // TODO: translation key
          header: 'Cyber IQ',
          valueSelector: (user: ManagerTeamUser) => (
            <CyberIqDetails>
              {getRankArrow(user.rank, user.previousRank)}
              <UserPoints className="user-points">
                <Icon width={20} height={20} icon={IconType.COIN} />
                {formatNumberWithCommas(user.cyberIqPoints)}
              </UserPoints>
            </CyberIqDetails>
          ),
          alignment: 'left',
          width: '100px',
        },
        {
          // TODO: translation key
          header: 'Training',
          valueSelector: (user: ManagerTeamUser) => (
            <TrainingProgress>
              <ProgressBarContainer>
                <ProgressBar>
                  <ProgressFill
                    progress={user.trainingProgress || 0}
                    backgroundColor={getProgressBarColor(user.trainingProgress || 0)}
                  />
                </ProgressBar>
              </ProgressBarContainer>
            </TrainingProgress>
          ),
        },
        {
          header: '',
          valueSelector: (user: ManagerTeamUser) =>
            !user.trainingCompleted ? (
              <TrainingPrimaryButton onClick={() => onNudgeUser(user)}>
                <NamedContentEntryText container="span" refKey="managerTeamCyberSecurityTraining" subKey="nudge" />
              </TrainingPrimaryButton>
            ) : null,
          alignment: 'right',
        },
      ],
    }),
    [],
  );
};

const UserPoints = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.medium};
  display: flex;
  align-items: center;
  gap: 8px;

  @media (max-width: 768px) {
    flex-direction: row-reverse;
    padding: 10px 8px;
    width: 90px;
    justify-content: flex-end;
    border-radius: ${({ theme }) => theme.borderRadius.extraLarge};
    background: ${({ theme }) => theme.colors.trainingCardBackground};
  }
`;

const UserName = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: ${({ theme }) => theme.fontSizes.medium};
`;

const Column = styled.div`
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 8px;
`;

const UserPropTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: ${({ theme }) => theme.fontSizes.medium};
`;

const UserImg = styled.img`
  border-radius: 50%;
  width: 40px;
  height: 40px;
  cursor: pointer;
`;

const TrainingProgress = styled.div`
  width: 130px;
`;
const CyberIqDetails = styled.div`
  display: flex;
  align-items: center;
  grid-template-columns: none;
  gap: 4px;
`;
