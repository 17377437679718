import { useRequestClient } from './useRequestClient';
import {
  useQuery,
  useSuspenseQuery,
  type QueryFunction,
  type QueryFunctionContext,
  type QueryKey,
  type UseQueryOptions,
  type UseQueryResult,
  type UseSuspenseQueryOptions,
  type UseSuspenseQueryResult,
} from '@tanstack/react-query';
import type { KyInstance, Options } from 'ky';
import { delay } from '../promise/delay';
import { useMemo } from 'react';

export const useLPSuspenseQuery = <T, TSelected = T, TError = unknown, TQueryKey extends QueryKey = QueryKey>(
  key: TQueryKey,
  pathOrQueryFn: string | QueryFunction<T, TQueryKey>,
  options?: Omit<UseQueryOptions<T, TError, TSelected, TQueryKey>, 'queryKey' | 'queryFn'>,
  minLoadTimeMs?: number,
  kyOptions?: Options,
  localPath?: boolean,
): UseSuspenseQueryResult<TSelected, TError> => {
  const queryOpts = useLPSuspenseQueryOpts<T, TSelected, TError, TQueryKey>(
    key,
    pathOrQueryFn,
    options,
    minLoadTimeMs,
    kyOptions,
    localPath,
  );
  return useSuspenseQuery<T, TError, TSelected, TQueryKey>(queryOpts);
};
type GetLPQueryFunctionOptions = {
  path: string;
  localPath?: boolean;
  kyInstance?: KyInstance;
  kyOptions?: Options;
};
export function useLPSuspenseQueryOpts<T, TSelected = T, TError = unknown, TQueryKey extends QueryKey = QueryKey>(
  key: TQueryKey,
  pathOrQueryFn: string | QueryFunction<T, TQueryKey>,
  options?: Omit<UseQueryOptions<T, TError, TSelected, TQueryKey>, 'queryKey' | 'queryFn'>,
  minLoadTimeMs?: number,
  kyOptions?: Options,
  localPath?: boolean,
) {
  const kyInstance = useRequestClient();

  return useMemo(() => {
    const qOpts: UseSuspenseQueryOptions<T, TError, TSelected, TQueryKey> = {
      staleTime: 3600000,
      ...options,
      queryKey: key,
      queryFn:
        typeof pathOrQueryFn === 'string'
          ? async (context: QueryFunctionContext<TQueryKey>) => {
              const defaultCCQueryFn = getLPQueryFunction<T, TQueryKey>({
                path: pathOrQueryFn,
                localPath,
                kyInstance,
                kyOptions,
              });
              const delayMs = minLoadTimeMs ?? 0;
              const delayProm = delayMs > 0 ? delay(delayMs) : Promise.resolve();
              const [result] = await Promise.all([defaultCCQueryFn(context), delayProm]);
              return result;
            }
          : pathOrQueryFn,
    };
    return qOpts;
  }, [key, kyInstance, kyOptions, localPath, minLoadTimeMs, options, pathOrQueryFn]);
}

function getLPQueryFunction<T, TQueryKey extends QueryKey = QueryKey>({
  path,
  localPath,
  kyInstance,
  kyOptions,
}: GetLPQueryFunctionOptions): QueryFunction<T, TQueryKey> {
  const baseUrl = import.meta.env.REACT_APP_LP_API_BASE_URL;
  if (!baseUrl) {
    throw Error('REACT_APP_LP_API_BASE_URL is not defined');
  }
  return async () => {
    if (!kyInstance) {
      throw new Error('Missing RequestClientProvider');
    }
    const url = `${localPath ? window.location.origin : baseUrl}${path}`;
    const result = await kyInstance<T>(url, kyOptions).json();
    return result;
  };
}

export const useLPQuery = <T, TSelected = T, TError = unknown, TQueryKey extends QueryKey = QueryKey>(
  key: TQueryKey,
  pathOrQueryFn: string | QueryFunction<T, TQueryKey>,
  options?: Omit<UseQueryOptions<T, TError, TSelected, TQueryKey>, 'queryKey' | 'queryFn'>,
  minLoadTimeMs?: number,
  kyOptions?: Options,
  localPath?: boolean,
): UseQueryResult<TSelected, TError> => {
  const kyInstance = useRequestClient();
  const queryOpts = useMemo(() => {
    const qOpts: UseQueryOptions<T, TError, TSelected, TQueryKey> = {
      staleTime: 3600000,
      ...options,
      queryKey: key,
      queryFn:
        typeof pathOrQueryFn === 'string'
          ? async (context: QueryFunctionContext<TQueryKey>) => {
              const defaultCCQueryFn = getLPQueryFunction<T, TQueryKey>({
                path: pathOrQueryFn,
                localPath,
                kyInstance,
                kyOptions,
              });
              const delayMs = minLoadTimeMs ?? 0;
              const delayProm = delayMs > 0 ? delay(delayMs) : Promise.resolve();
              const [result] = await Promise.all([defaultCCQueryFn(context), delayProm]);
              return result;
            }
          : pathOrQueryFn,
    };
    return qOpts;
  }, [key, kyInstance, kyOptions, localPath, minLoadTimeMs, options, pathOrQueryFn]);
  return useQuery<T, TError, TSelected, TQueryKey>(queryOpts);
};
