import { type ReactNode, useState } from 'react';
import styled from 'styled-components';

type TooltipProps = {
  content: ReactNode;
  placement?: 'top' | 'bottom' | 'left' | 'right';
  offset?: number;
  disabled?: boolean;
  children: ReactNode;
};

export const Tooltip = ({ content, placement = 'top', offset = 10, disabled = false, children }: TooltipProps) => {
  const [visible, setVisible] = useState(false);

  const onChangeVisibility = (visible: boolean) => {
    if (!disabled) {
      setVisible(visible);
    }
  };

  const handleTouch = (e: React.MouseEvent) => {
    e.stopPropagation();
    onChangeVisibility(!visible);
  };

  const isTouchDevice = () => 'ontouchstart' in window || navigator.maxTouchPoints > 0;

  return (
    <TooltipContainer
      onClick={isTouchDevice() ? handleTouch : undefined}
      onMouseEnter={() => onChangeVisibility(true)}
      onMouseLeave={() => onChangeVisibility(false)}
    >
      {children}
      <TooltipContent isVisible={visible} placement={placement} offset={offset}>
        <TooltipArrow placement={placement} />
        {content}
      </TooltipContent>
    </TooltipContainer>
  );
};

const TooltipContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const TooltipContent = styled.div.withConfig({
  shouldForwardProp: (prop) => !['isVisible', 'placement', 'offset'].includes(prop),
})<{ isVisible: boolean; placement: string; offset: number }>`
  position: absolute;
  z-index: 10;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
  transition: ${({ isVisible }) => (isVisible ? 'opacity 0.3s ease-in-out' : 'none')};

  ${({ placement, offset }) => {
    switch (placement) {
      case 'top':
        return `
          bottom: calc(100% + ${offset}px);
          left: 50%;
          transform: translateX(-50%);
        `;
      case 'bottom':
        return `
          top: calc(100% + ${offset}px);
          left: 50%;
          transform: translateX(-50%);
        `;
      case 'left':
        return `
          right: calc(100% + ${offset}px);
          top: 50%;
          transform: translateY(-50%);
        `;
      case 'right':
        return `
          left: calc(100% + ${offset}px);
          top: 50%;
          transform: translateY(-50%);
        `;
      default:
        return '';
    }
  }}
`;

const TooltipArrow = styled.div.withConfig({
  shouldForwardProp: (prop) => prop !== 'placement',
})<{ placement: string }>`
  position: absolute;
  width: 0;
  height: 0;

  ${({ theme, placement }) => {
    switch (placement) {
      case 'top':
        return `
          bottom: -5px;
          left: 50%;
          transform: translateX(-50%);
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          border-top: 5px solid ${theme.colors.panelBackground};
        `;
      case 'bottom':
        return `
          top: -5px;
          left: 50%;
          transform: translateX(-50%);
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          border-bottom: 5px solid ${theme.colors.panelBackground};
        `;
      case 'left':
        return `
          right: -5px;
          top: 50%;
          transform: translateY(-50%);
          border-top: 5px solid transparent;
          border-bottom: 5px solid transparent;
          border-left: 5px solid ${theme.colors.panelBackground};
        `;
      case 'right':
        return `
          left: -5px;
          top: 50%;
          transform: translateY(-50%);
          border-top: 5px solid transparent;
          border-bottom: 5px solid transparent;
          border-right: 5px solid ${theme.colors.panelBackground};
        `;
      default:
        return '';
    }
  }}
`;
