import { type UserLevelDetailsType, UserLevels } from '../../../../lib/enums/userLevels';
import styled from 'styled-components';

type LevelTooltipProps = {
  tooltipDetails: UserLevelDetailsType[UserLevels] | undefined;
};

export const LevelTooltip = ({ tooltipDetails }: LevelTooltipProps) => {
  if (!tooltipDetails) {
    return null;
  }

  return (
    <TooltipContainer>
      {tooltipDetails.image ? tooltipDetails.image : null}
      <ContentWrapper>
        <Name>
          {tooltipDetails.level}: <b>{tooltipDetails.name}</b>
        </Name>
        <IntroText>{tooltipDetails.introText}</IntroText>
        <Description>{tooltipDetails.description}</Description>
      </ContentWrapper>
    </TooltipContainer>
  );
};

const TooltipContainer = styled.div`
  display: grid;
  grid-template-columns: 130px 1fr;
  gap: 16px;
  border: 1px solid ${({ theme }) => theme.colors.pageBackground};
  border-radius: ${({ theme }) => theme.borderRadius.normal};
  box-shadow: ${({ theme }) => theme.shadow.light};
  background-color: ${({ theme }) => theme.colors.panelBackground};
  padding: 8px;
  width: max-content;
  max-width: 440px;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Name = styled.span`
  font-size: ${({ theme }) => theme.fontSizes.normal};
  color: ${({ theme }) => theme.colors.heading};

  > b {
    text-transform: uppercase;
  }
`;

const IntroText = styled.span`
  font-size: ${({ theme }) => theme.fontSizes.normal};
  color: ${({ theme }) => theme.colors.normal};
  font-weight: 400;
`;

const Description = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.normal};
  color: ${({ theme }) => theme.colors.normal};
  font-weight: 400;
`;
