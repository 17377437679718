import type React from 'react';
import { useMemo } from 'react';
import { isRouteErrorResponse, useRouteError } from 'react-router';
import { ErrorView } from '../components/ErrorView';

export const ErrorRoute: React.FC = () => {
  const error = useRouteError();
  console.error({ error });

  const message = useMemo(
    () => (isRouteErrorResponse(error) ? error.statusText : error instanceof Error ? error.message : 'Unknown Error'),
    [error],
  );
  return <ErrorView error={{ message }} />;
};
