import React, { useMemo, type PropsWithChildren } from 'react';
import { StyleSheetManager, ThemeProvider, type DefaultTheme } from 'styled-components';
import rtlPlugin from 'stylis-plugin-rtl';
import { GlobalStyles } from './GlobalStyles';
import { WrapHelmet } from './WrapHelmet';
import { useIsRTLLayout } from '../appState/hooks/useIsRTLLayout';
import { DirectionProvider } from '@radix-ui/react-direction';
import { getTheme } from '../getTheme';
import { usePortalGuard } from '../appState/hooks/auth/usePortalGuard';
import { useColorTheme } from '../lib/hooks/useColorTheme/useColorTheme';

export const GlobalWrapper: React.FC<PropsWithChildren> = ({ children }) => {
  const stylisPlugins = useStylisPlugins();
  const isRTLLayout = useIsRTLLayout();
  const colorTheme = useColorTheme();

  const theme: DefaultTheme = useMemo(() => getTheme({ rtl: isRTLLayout, colorTheme }), [isRTLLayout, colorTheme]);

  usePortalGuard();

  return (
    <DirectionProvider dir={isRTLLayout ? 'rtl' : 'ltr'}>
      <ThemeProvider theme={theme}>
        <WrapHelmet>
          <StyleSheetManager stylisPlugins={stylisPlugins}>
            <GlobalStyles />
            {children}
          </StyleSheetManager>
        </WrapHelmet>
      </ThemeProvider>
    </DirectionProvider>
  );
};
function useStylisPlugins() {
  const isRTLLayout = useIsRTLLayout();
  const stylisPlugins = useMemo(() => (isRTLLayout ? [rtlPlugin] : []), [isRTLLayout]);
  return stylisPlugins;
}
