import { Outlet, useLocation } from 'react-router';
import styled, { useTheme } from 'styled-components';
import { TopBar } from '../components/TopBar';
import type { MenuPage } from '../topBarMenuPages/MenuPage';
import { Suspense } from 'react';
import { Spinner } from '../components/Spinner/Spinner';
import { useQueryErrorResetBoundary } from '@tanstack/react-query';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorView } from '../components/ErrorView';

export const MainLayout: React.FC<{
  topBarMenuPages: MenuPage[];
  managerView?: boolean;
}> = ({ topBarMenuPages, managerView }) => {
  const theme = useTheme();
  const { reset } = useQueryErrorResetBoundary();
  const location = useLocation();

  return (
    <Layout>
      <TopBar topBarMenuPages={topBarMenuPages} managerView={managerView} />
      <div>
        <Suspense
          fallback={
            <div
              style={{ display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center', minHeight: '200px' }}
            >
              <Spinner size="48px" color={theme.colors.primary} />
            </div>
          }
        >
          <ErrorBoundary
            onReset={reset}
            resetKeys={[location.pathname]}
            fallbackRender={({ error, resetErrorBoundary }) => (
              <ErrorView error={error} onTryAgain={resetErrorBoundary} />
            )}
          >
            <Outlet />
          </ErrorBoundary>
        </Suspense>
      </div>
    </Layout>
  );
};
const Layout = styled.div`
  display: grid;
  grid-template-rows: min-content 1fr;
  gap: 12px;
  min-height: 100%;
  max-width: 1600px;
  margin: 0 auto;
`;
