import { UserLevels } from '../lib/enums/userLevels';
import type { ManagerTeamUser } from '../lib/types/ManagerTeamUser';

type TopQueryParams = {
  mode: 'top';
  type: string;
  count: number;
};

type PagedQueryParams = {
  mode: 'paged';
  type: string;
  skip: number;
  take: number;
};

type ManagerTeamQueryParams = TopQueryParams | PagedQueryParams;

export type ManagerTeamPagedResults = {
  total: number;
  skip: number | null;
  take: number | null;
  values: ManagerTeamUser[];
};

// TODO: Remove mocks
const DEFAULT_DATA: ManagerTeamUser[] = [
  {
    learnerId: '1',
    name: 'John Doe',
    avatar: null,
    jobTitle: 'Software Engineer',
    department: 'Engineering',
    cyberIqPoints: 95,
    trainingCompleted: true,
    trainingProgress: 100,
    previousRank: 1,
    levelId: UserLevels.determined,
    rank: 1,
  },
  {
    learnerId: '2',
    name: 'Jane Smith',
    avatar: null,
    jobTitle: 'Product Manager',
    department: 'Product',
    cyberIqPoints: 88,
    trainingCompleted: false,
    trainingProgress: 75,
    previousRank: 2,
    levelId: UserLevels.determined,
    rank: 2,
  },
  {
    learnerId: '3',
    name: 'Alice Johnson',
    avatar: null,
    jobTitle: 'UX Designer',
    department: 'Design',
    cyberIqPoints: 92,
    trainingCompleted: true,
    trainingProgress: 100,
    previousRank: 3,
    levelId: UserLevels.determined,
    rank: 3,
  },
  {
    learnerId: '4',
    name: 'Bob Brown',
    avatar: null,
    jobTitle: 'Data Scientist',
    department: 'Data',
    cyberIqPoints: 85,
    trainingCompleted: false,
    trainingProgress: 60,
    previousRank: 4,
    levelId: UserLevels.determined,
    rank: 4,
  },
  {
    learnerId: '5',
    name: 'Chris Stark',
    avatar: null,
    jobTitle: 'Product Manager',
    department: 'Product',
    cyberIqPoints: 88,
    trainingCompleted: false,
    trainingProgress: 75,
    previousRank: 5,
    levelId: UserLevels.determined,
    rank: 5,
  },
  {
    learnerId: '6',
    name: 'Alex Smith',
    avatar: null,
    jobTitle: 'UX Designer',
    department: 'Design',
    cyberIqPoints: 92,
    trainingCompleted: true,
    trainingProgress: 100,
    previousRank: 6,
    levelId: UserLevels.determined,
    rank: 6,
  },
  {
    learnerId: '7',
    name: 'Elvis Presley',
    avatar: null,
    jobTitle: 'Data Scientist',
    department: 'Data',
    cyberIqPoints: 85,
    trainingCompleted: false,
    trainingProgress: 50,
    previousRank: 7,
    levelId: UserLevels.determined,
    rank: 7,
  },
  {
    learnerId: '8',
    name: 'Mara Doe',
    avatar: null,
    jobTitle: 'UX Designer',
    department: 'Design',
    cyberIqPoints: 25,
    trainingCompleted: false,
    trainingProgress: 90,
    previousRank: 8,
    levelId: UserLevels.determined,
    rank: 8,
  },
  {
    learnerId: '9',
    name: 'Sugar Brown',
    avatar: null,
    jobTitle: 'Data Scientist',
    department: 'Data',
    cyberIqPoints: 15,
    trainingCompleted: false,
    trainingProgress: 20,
    previousRank: 9,
    levelId: UserLevels.determined,
    rank: 9,
  },
];

export const useManagerTeamQuery = (params?: ManagerTeamQueryParams) => {
  // TODO: Integrate manager's team details once ready from BE
  console.log(params);

  // const { type, mode } = params;

  // let queryKey: (string | ManagerTeamQueryParams)[];
  // let endpoint: string;

  // if (mode === 'top') {
  //   const { count } = params;
  //   queryKey = ['ManagerTeamQuery', params];
  //   endpoint = `/manager/team/${type === 'team' ? 'company' : type}/top?count=${count}`;
  // } else {
  //   const { skip, take } = params;
  //   queryKey = ['ManagerTeamQuery', params];
  //   endpoint = `/manager/team/${type}?skip=${skip}&take=${take}`;
  // }

  // return useLPSuspenseQuery<ManagerTeamUser[] | ManagerTeamPagedResults>(queryKey, endpoint);
  return DEFAULT_DATA;
};
