import type { FC } from 'react';
import styled from 'styled-components';
import { Card } from '../../Card';
import noAvailableDataImage from '../assets/NoAvailableData.png';
import { NamedContentEntryText } from '../../ContentEntryText/NamedContentEntryText';

export const NoUserDataCard: FC = () => {
  return (
    <CardContainer>
      <img width={96} height={96} src={noAvailableDataImage} alt="No data available" />
      <HeadingText>
        <NamedContentEntryText refKey={'scoreBreakdownEmptyDataCard'} container={'span'} subKey={'heading'} />
      </HeadingText>
      <DescriptionText>
        <NamedContentEntryText refKey={'scoreBreakdownEmptyDataCard'} container={'span'} subKey={'description'} />
      </DescriptionText>
    </CardContainer>
  );
};

const CardContainer = styled(Card)`
  background-color: ${({ theme }) => theme.colors.trainingCardBackground};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  text-align: center;
  box-shadow: unset;
`;

const HeadingText = styled.span`
  font-size: ${({ theme }) => theme.fontSizes.medium};
  color: ${({ theme }) => theme.colors.heading};
  font-weight: 600;
`;

const DescriptionText = styled.span`
  font-size: ${({ theme }) => theme.fontSizes.small};
  color: ${({ theme }) => theme.colors.normal};
  font-weight: 400;
`;
