import React, { createContext, useCallback, useContext, useEffect, useMemo, type PropsWithChildren } from 'react';
import { useZustandStore } from '../../../appState/StoresProvider';
import { useMeQuery } from '../../../apiHooks/useMeQuery';
import { useQueryClient } from '@tanstack/react-query';
import { getAllContentEntryRefs } from '../../../contentEntryRefs/contentEntryRefs';
import { useContentEntriesBatcher } from '../contentEntry/util/hooks/useContentEntriesBatcher';
import { createContentEntryQueryOptions } from '../contentEntry/util/createContentEntryQueryOptions';

type LanguageContext = readonly [language: string | undefined, setLanguage: (language: string) => void];

const defaultContextValue: LanguageContext = [
  undefined,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  () => {},
] as const;

export function createLanguageContext() {
  const LanguageContext = createContext<LanguageContext>(defaultContextValue);
  const LanguageContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const meData = useMeQuery();
    const languageCode = useZustandStore('language', (v) => v.languageCode);
    const setLanguageCodeInStore = useZustandStore('language', (v) => v.changeLanguage);
    const prefetchContentEntries = usePrefetchContentEntries();
    const setLanguageCode = useCallback(
      (language: string) => {
        prefetchContentEntries(language);
        setLanguageCodeInStore(language);
      },
      [setLanguageCodeInStore, prefetchContentEntries],
    );
    const contextValue = useMemo(() => [languageCode, setLanguageCode] as const, [languageCode, setLanguageCode]);
    useEffect(() => {
      const lc = meData.data?.languageCode;
      const langCode = lc === null ? 'en-GB' : lc;
      if (!langCode || languageCode != null) {
        return;
      }
      prefetchContentEntries(langCode);
      setLanguageCode(langCode);
    }, [meData.data?.languageCode, languageCode, setLanguageCode, prefetchContentEntries]);

    return <LanguageContext.Provider value={contextValue}>{children}</LanguageContext.Provider>;
  };
  function useLanguageContext() {
    const language = useContext(LanguageContext);
    return language;
  }
  return { LanguageContextProvider, useLanguageContext };
}
function usePrefetchContentEntries() {
  const queryClient = useQueryClient();
  const batcher = useContentEntriesBatcher();
  const prefetch = useCallback(
    (langCode: string) => {
      const refs = getAllContentEntryRefs(langCode);
      const queryOpts = refs.map((ref) => createContentEntryQueryOptions(ref, batcher));
      queryOpts.forEach((opts) => {
        queryClient.prefetchQuery(opts);
      });
    },
    [batcher, queryClient],
  );
  return prefetch;
}
