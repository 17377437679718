import { useLPSuspenseQuery } from '../lib/hooks/useLPQuery';

export function useCyberIQBadgesQuery() {
  return useLPSuspenseQuery<CyberIQBadges>(['cyber-iq-badges'], '/cyberIq/badges');
}
type CyberIQBadges = CyberIQBadge[];

export type CyberIQBadge = {
  id: string;
  points: number;
  assetUrl: string;
};
