export type LoginThemeConfig = {
  defaultType: LoginThemes;
  defaultSplitScreenURL: string;
  defaultFullBackgroundURL: string;
};

export enum LoginThemes {
  'splitScreen' = 'splitScreen',
  'fullBackground' = 'fullBackground',
}

export function useDefaultLoginTheme(): LoginThemeConfig {
  const loginTheme: LoginThemeConfig = {
    defaultType: LoginThemes.splitScreen,
    defaultSplitScreenURL: 'https://client-resources.outthink.io/auth/default-ciq-split.png',
    defaultFullBackgroundURL: 'https://client-resources.outthink.io/auth/default-ciq-full.png ',
  };

  return loginTheme;
}
