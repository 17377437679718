import { useMemo } from 'react';
import { getTwoLetterLangCode } from '../../lib/language/getTwoLetterLangCode';
import { useLanguageContext } from '../../lib/hooks/useLanguageContext/useLanguageContext';

const rtlLanguages = new Set(['ar', 'he']);

export const useIsRTLLayout = (): boolean => {
  const [language] = useLanguageContext();
  const twoLetterCode = useMemo(() => (language ? getTwoLetterLangCode(language) : undefined), [language]);
  const isRTL = useMemo(() => !!twoLetterCode && rtlLanguages.has(twoLetterCode), [twoLetterCode]);
  return isRTL;
};
