import type { FC } from 'react';
import { IconContainer, TrainingSecondaryButton, Title, TrainingCard } from '../TrainingCard';
import Play from '../../../../assets/Play.svg';
import { Coin } from '../../../Props';
import { NamedContentEntryText } from '../../../ContentEntryText/NamedContentEntryText';
import type { EnrolledCampaignListResponse } from '../../../../apiHooks/useCampaignsQuery';

type VoluntaryTrainingCardProps = {
  campaign: EnrolledCampaignListResponse;
};

export const VoluntaryTrainingCard: FC<VoluntaryTrainingCardProps> = ({ campaign }: VoluntaryTrainingCardProps) => {
  const { name, pointsAward, campaignUrl } = campaign;

  return (
    <TrainingCard>
      <IconContainer>
        <Play className="rtl-flip" />
      </IconContainer>
      <Title>{name}</Title>
      {pointsAward && <Coin value={pointsAward} />}
      <TrainingSecondaryButton
        disabled={!campaignUrl}
        onClick={() => {
          if (campaignUrl) {
            window.open(campaignUrl, '_blank', 'noopener,noreferrer');
          }
        }}
      >
        <NamedContentEntryText container="span" refKey="yourTraining" subKey="startNow" />
      </TrainingSecondaryButton>
    </TrainingCard>
  );
};
