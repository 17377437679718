import { type ButtonHTMLAttributes, type FC, type PropsWithChildren } from 'react';
import styled from 'styled-components';

type ButtonProps = {
  onClick?: () => void;
  disabled?: boolean;
  size?: 'small' | 'medium' | 'large';
  backgroundColor?: string;
  color?: string;
  borderColor?: string;
  borderRadius?: string;
  padding?: string;
} & ButtonHTMLAttributes<HTMLButtonElement>;

export const Button: FC<PropsWithChildren<ButtonProps>> = ({
  children,
  onClick,
  disabled = false,
  size = 'medium',
  backgroundColor,
  color,
  borderColor,
  borderRadius,
  padding,
  ...props
}: ButtonProps) => (
  <StyledButton
    onClick={onClick}
    disabled={disabled}
    size={size}
    backgroundColor={backgroundColor}
    color={color}
    borderColor={borderColor}
    borderRadius={borderRadius}
    padding={padding}
    {...props}
  >
    {children}
  </StyledButton>
);

const StyledButton = styled.button.withConfig({
  shouldForwardProp: (prop) => prop !== 'backgroundColor' && prop !== 'borderRadius' && prop !== 'borderColor',
})<{
  size: 'small' | 'medium' | 'large';
  disabled?: boolean;
  backgroundColor?: string;
  color?: string;
  borderColor?: string;
  borderRadius?: string;
  padding?: string;
}>`
  border: ${({ borderColor }) => (borderColor ? `1px solid ${borderColor}` : 'none')};
  border-radius: ${({ theme, borderRadius }) => borderRadius ?? theme.borderRadius.normal};
  cursor: pointer;
  color: ${({ theme, color }) => color ?? theme.colors.primaryContrast};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  background-color: ${({ theme, backgroundColor }) => backgroundColor ?? theme.colors.primary};
  font-size: ${({ size, theme }) =>
    size === 'small' ? theme.fontSizes.normal : size === 'large' ? theme.fontSizes.large : theme.fontSizes.medium};
  padding: ${({ size, theme, padding }) => padding ?? theme.buttonSize[size]};

  &:hover {
    filter: brightness(0.9);
  }
`;
