import React, { useEffect, useTransition } from 'react';

export function usePropertyTransition<T>(value: T) {
  const [isPending, startTrans] = useTransition();
  const [val, setVal] = React.useState<T>(() => value);
  useEffect(() => {
    startTrans(() => {
      setVal(value);
    });
  }, [value]);
  return React.useMemo(() => [isPending, val] as const, [isPending, val]);
}
