import type { KyInstance } from 'ky';
import { type ContentEntryRef } from '../types/ContentEntryRef';
import { type ContentEntryData } from '../types/ContentEntryResponse';
import type { FetchContentEntriesFunc } from '../types/FetchContentEntriesFunc';
import type { ContentEntriesOfContentEntryRefs } from '../types/ContentEntriesOfContentEntryRefs';

export const fetchContentEntries =
  (requestClient: KyInstance | undefined): FetchContentEntriesFunc =>
  async <T extends ContentEntryRef<ContentEntryData>[]>(
    refs: readonly [...T],
  ): Promise<ContentEntriesOfContentEntryRefs<T>> => {
    if (refs.length === 0 || !requestClient) {
      return [] as ContentEntriesOfContentEntryRefs<T>;
    }
    if (!refs.every((ref) => ref.languageCode === refs[0].languageCode && ref.moduleId === refs[0].moduleId)) {
      throw new Error('All refs must have the same languageCode and moduleId');
    }
    const baseURL = import.meta.env.REACT_APP_LP_API_BASE_URL;
    if (!baseURL) {
      throw new Error('REACT_APP_LP_API_BASE_URL is not set');
    }

    const u = new URL(`${baseURL}/content`);
    u.searchParams.append('LanguageCode', refs[0].languageCode);
    u.searchParams.append('ModuleId', refs[0].moduleId);
    u.searchParams.set('Entries', refs.map((ref) => ref.contentId).join(','));
    return requestClient
      .get(u.href, { headers: { TrainingDeliveryPath: 'DEMO' } })
      .json<ContentEntriesOfContentEntryRefs<T>>();
  };
