import { Outlet, createBrowserRouter, redirect, useParams, type RouteObject } from 'react-router';
import { PreserveSearchParams } from './routes/PreserveSearchParams';
import { ErrorRoute } from './routes/Error';
import { MainLayout } from './routes/MainLayout';
import { ProtectedRoute } from './routes/ProtectedRoute';
import { Login } from './routes/Login';
import { userTopbarMenuPages } from './topBarMenuPages/userTopbarMenuPages';
import { isUUID } from './lib/uuid/isUUID';
import { useEffect, useMemo } from 'react';
import { useZustandStore } from './appState/StoresProvider';
import { OneTimePassword } from './routes/OneTimePassword';
import { Spinner } from './components/Spinner/Spinner';
import styled, { useTheme } from 'styled-components';
import { useLoginStatus } from './appState/hooks/auth/useLoginStatus';
import { managerTopbarMenuPages } from './topBarMenuPages/managerTopbarMenuPages';
import { PrefetchLanguages } from './routes/PrefetchLanguages';

const searchParamsToPreserve: string[] = ['contentEntryFallbacks'];

function getAppRoutes({
  isOutthinkOrigin,
  otpRequested,
  isManager,
  loginStatus,
}: {
  isOutthinkOrigin: boolean;
  otpRequested: boolean;
  isManager?: boolean;
  loginStatus: 'loggedOut' | 'otpRequested' | 'loggedIn';
}) {
  const basicAppRoutes: RouteObject[] = [
    {
      index: true,
      loader({ params: { tenantId } }) {
        const loaderResponse = isOutthinkOrigin
          ? (testTenantId(tenantId) ?? redirect('overview'))
          : redirect('overview');
        return loaderResponse;
      },
    },
    {
      path: '',
      element: (
        <ProtectedRoute
          storePreviousLocationOnRedirect
          allowWhen={(loginStatus) => loginStatus === 'loggedIn' && isManager != null}
          redirectTo="login"
        />
      ),
      children: [
        {
          path: '',
          element: <PrefetchLanguages />,
          children: [
            {
              path: '',
              element: <MainLayout topBarMenuPages={userTopbarMenuPages} />,
              children: userTopbarMenuPages,
            },
            {
              path: 'manager',
              element: (
                <ProtectedRoute
                  allowWhen={() => isManager === true}
                  redirectTo="../overview"
                  storePreviousLocationOnRedirect
                />
              ),
              children: [
                {
                  index: true,
                  loader() {
                    return redirect('overview');
                  },
                },
                {
                  path: '',
                  element: <MainLayout topBarMenuPages={managerTopbarMenuPages} managerView />,
                  children: managerTopbarMenuPages,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      path: 'login',
      element: (
        <ProtectedRoute
          restoreStoredLocationOnRedirect
          allowWhen={(loginStatus) => loginStatus !== 'loggedIn' || isManager == null}
          fallbackRedirectTo="../overview"
        />
      ),
      children: [
        {
          path: '',
          element: <LoginRoute />,
          children: [
            {
              path: '',
              element:
                otpRequested || (loginStatus === 'loggedIn' && isManager == null) ? <OneTimePassword /> : <Login />,
            },
          ],
        },
      ],
    },
  ];
  return isOutthinkOrigin ? withTenantId(basicAppRoutes) : basicAppRoutes;
}

export function getRouter({
  origin,
  otpRequested,
  isManager,
  loginStatus,
}: {
  origin: string;
  otpRequested: boolean;
  isManager?: boolean;
  loginStatus: 'loggedOut' | 'otpRequested' | 'loggedIn';
}) {
  const isOutthinkOrigin = origin === import.meta.env.REACT_APP_OUTTHINK_ORIGIN;
  const appRoutes = getAppRoutes({ isOutthinkOrigin, otpRequested, isManager, loginStatus });
  return createBrowserRouter([
    {
      path: '',
      element: <Initialization />,
      errorElement: <ErrorRoute />,

      children: [
        {
          path: '',
          element: <PreserveSearchParams paramKeys={searchParamsToPreserve} />,

          children: [
            ...appRoutes,
            {
              path: '',
              loader({ params: { tenantId } }) {
                if (isOutthinkOrigin) {
                  try {
                    testTenantId(tenantId);
                  } catch {
                    return redirect('/DEMO');
                  }
                }
                throw jsonStatusResponse({ status: 404, statusText: 'Not found' });
              },
            },
          ],
        },
      ],
    },
  ]);
}

function withTenantId(appRoutes: RouteObject[]): RouteObject[] {
  return [
    {
      path: ':tenantId',
      loader({ params }) {
        return testTenantId(params.tenantId);
      },
      children: appRoutes,
    },
  ];
}
function testTenantId(tenantId: string | undefined) {
  if (!tenantId) {
    throw jsonStatusResponse({ status: 404, statusText: 'Not found. Invalid tenant code.' });
  }
  const isSegmentTenantCode = /^[A-Z]{4}$/.exec(tenantId);
  const isSegmentTrainingDeliveryPath = /^[a-z0-9]{4,25}$/.exec(tenantId);
  const isSegmentUUID = isUUID(tenantId);

  if (!isSegmentTenantCode && !isSegmentTrainingDeliveryPath && !isSegmentUUID) {
    throw jsonStatusResponse({ status: 404, statusText: 'Not found. Invalid tenant code.' });
  }
  return null;
}

const Initialization: React.FC = () => {
  const { tenantId } = useParams();
  const location = window.location;
  const init = useZustandStore('auth', (v) => v.init);
  const loginSettings = useZustandStore('auth', (v) => ('loginSettings' in v ? v.loginSettings : undefined));
  const loginStatus = useLoginStatus();
  const isOutthinkOrigin = useMemo(
    () => location.origin === import.meta.env.REACT_APP_OUTTHINK_ORIGIN,
    [location.origin],
  );

  useEffect(() => {
    init(tenantId);
  }, [init, isOutthinkOrigin, tenantId, loginStatus]);

  const theme = useTheme();

  return loginSettings ? (
    <Outlet />
  ) : (
    <SpinnerContainer>
      <Spinner size={'64px'} color={theme.colors.primary} />
    </SpinnerContainer>
  );
};

const LoginRoute: React.FC = () => {
  // why? because we need to clear the language when we go to the login page
  // to prevent RTL mode from being applied to the login page
  // (login pages are always english for now)
  const clearLanguage = useZustandStore('language', (v) => v.clearLanguage);
  useEffect(() => {
    clearLanguage();
  }, [clearLanguage]);
  return <Outlet />;
};

const SpinnerContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

function jsonStatusResponse(payload: { status: number; statusText: string }) {
  return new Response(JSON.stringify(payload), {
    status: payload.status,
    statusText: payload.statusText,
    headers: { 'Content-Type': 'application/json' },
  });
}
