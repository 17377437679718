import { useEffect, useMemo, useState } from 'react';
import { getRouter } from '../../getRouter';
import { useZustandStore } from '../../appState/StoresProvider';
import { useMeQuery } from '../../apiHooks/useMeQuery';
import { useLoginStatus } from '../../appState/hooks/auth/useLoginStatus';

export function useRouter() {
  const location = window.location;
  const otpRequestInfo = useZustandStore('auth', (v) =>
    v.authenticationState === 'otpRequested' ? v.otpRequestInfo : undefined,
  );
  const [origin, setOrigin] = useState(location.origin);
  useEffect(() => {
    setOrigin(location.origin);
  }, [location.origin]);
  const loginStatus = useLoginStatus();
  const { data: meData } = useMeQuery();
  const router = useMemo(() => {
    return getRouter({
      origin,
      otpRequested: !!otpRequestInfo,
      isManager: meData ? meData.portalRole === 'manager' : undefined,
      loginStatus,
    }); //, requestHeaders);
  }, [meData, origin, otpRequestInfo, loginStatus]); //, requestHeaders]);
  return router;
}
