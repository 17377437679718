import type { FC } from 'react';
import styled from 'styled-components';
import { useLanguageContext } from '../../../lib/hooks/useLanguageContext';
import { CampaignActionType } from '../../../apiHooks/useScoreBreakdownByUserQuery';
import { NamedContentEntryText } from '../../ContentEntryText/NamedContentEntryText';

type ActionTooltipProps = {
  date: string;
  actionResult: CampaignActionType;
};

export const ActionTooltip: FC<ActionTooltipProps> = ({ date, actionResult }: ActionTooltipProps) => {
  const [langCode] = useLanguageContext();

  const getResultDescription = () => {
    if (actionResult === CampaignActionType.SUCCESS) {
      return <NamedContentEntryText refKey={'scoreBreakdownTooltips'} container={'span'} subKey={'reported'} />;
    }
    if (actionResult === CampaignActionType.FAIL) {
      return <NamedContentEntryText refKey={'scoreBreakdownTooltips'} container={'span'} subKey={'compromised'} />;
    }
    if (actionResult === CampaignActionType.NEUTRAL) {
      return <NamedContentEntryText refKey={'scoreBreakdownTooltips'} container={'span'} subKey={'noAction'} />;
    }

    return '';
  };

  return (
    <TooltipContainer>
      <DateLabel>
        <NamedContentEntryText refKey={'scoreBreakdownTooltips'} container={'span'} subKey={'dateLabel'} />:{' '}
        {new Date(date).toLocaleString(langCode, { month: 'numeric', day: 'numeric', year: 'numeric' })}
      </DateLabel>
      <Description>{getResultDescription()}</Description>
    </TooltipContainer>
  );
};

const TooltipContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.panelBackground};
  box-shadow: ${({ theme }) => theme.shadow.light};
  padding: 8px;
  width: max-content;
  min-width: 200px;
  max-width: 300px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  border-radius: ${({ theme }) => theme.borderRadius.normal};
  outline: 1px solid ${({ theme }) => theme.colors.tableBorder};
`;

const DateLabel = styled.label`
  font-size: ${({ theme }) => theme.fontSizes.normal};
  font-weight: 500;
  color: ${({ theme }) => theme.colors.heading};
`;

const Description = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.normal};
  font-weight: 400;
  color: ${({ theme }) => theme.colors.normal};
`;
