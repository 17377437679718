import { useEffect, useState, type ComponentProps, type FC } from 'react';
import { useIsRTLLayout } from '../../../appState/hooks/useIsRTLLayout';
import { v4 as uuidv4 } from 'uuid';

/**
 * Exists only to patch an issue where Chromium browsers mess up parenthesis directionality
 * when switching between RTL and LTR languages.
 */
export const RTLFixSpan: FC<ComponentProps<'span'>> = ({ children, ...props }) => {
  const isRTL = useIsRTLLayout();
  const [key, setKey] = useState(() => uuidv4());
  useEffect(() => {
    setTimeout(() => setKey(() => uuidv4()), 0);
  }, [isRTL]);
  return (
    <span {...props} key={key} dir={isRTL ? 'rtl' : 'ltr'}>
      {children}
    </span>
  );
};
