import { type FC, useState, useCallback } from 'react';
import styled, { useTheme } from 'styled-components';
import { ValidateOTPCard } from './components/ValidateOTPCard';
import { useZustandStore } from '../../appState/StoresProvider';
import { useOtpRequestExpiryHandling } from './hooks/useOtpRequestExpiryHandling';
import { Spinner } from '../Spinner/Spinner';

export const OneTimePassword: FC = () => {
  const resetOtpRequestInfo = useZustandStore('auth', (v) => v.resetOtpRequestInfo);
  const requestOTP = useZustandStore('auth', (v) => v.requestOTP);
  const signInWithOTP = useZustandStore('auth', (v) => v.signInWithOTP);
  const waiting = useZustandStore('auth', (v) => v.isFetching);
  const [error, setError] = useState<string | undefined>(undefined);
  const [buttonDisabled, setButtonDisabled] = useState<boolean | undefined>(undefined);

  const otpRequestInfo = useOtpRequestInfo();
  const { normalizedTimeToExpiry, canResend } = useOtpRequestExpiryHandling();

  const handleBack = useCallback(() => {
    resetOtpRequestInfo();
  }, [resetOtpRequestInfo]);

  const handleResend = useCallback(() => {
    if (otpRequestInfo == null) {
      return;
    }
    setError(undefined);
    requestOTP(otpRequestInfo.email).then((status) => {
      if (!status.success) {
        setError(status.error);
      }
    });
  }, [otpRequestInfo, requestOTP]);

  const handeSubmit = useCallback(
    (code: string) => {
      signInWithOTP(code).then((status) => {
        if (!status.success) {
          if (status.code === 429) {
            setButtonDisabled(true);
            setTimeout(() => {
              setButtonDisabled(false);
            }, 10_000);
          }
          setError(status.error);
        }
      });
    },
    [signInWithOTP],
  );
  const theme = useTheme();

  return (
    <OTPContainer>
      {otpRequestInfo ? (
        <ValidateOTPCard
          email={otpRequestInfo.email}
          normalizedTimeToExpiry={normalizedTimeToExpiry}
          canResend={canResend}
          handleBack={handleBack}
          handleResend={handleResend}
          handleSubmit={handeSubmit}
          waiting={waiting}
          errorText={error}
          buttonDisabled={buttonDisabled}
        />
      ) : (
        <Spinner color={theme.colors.primary} size="48px" />
      )}
    </OTPContainer>
  );
};

const OTPContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;
  background: ${({ theme }) => theme.colors.pageBackground};
`;
function useOtpRequestInfo() {
  const otpRequestInfo = useZustandStore('auth', (v) =>
    v.authenticationState === 'otpRequested' ? v.otpRequestInfo : undefined,
  );
  // if (otpRequestInfo == null) {
  //   throw Error('No OTP request info available. This is unexpected');
  // }
  return otpRequestInfo;
}
