import { useLPSuspenseQuery } from '../lib/hooks/useLPQuery';

export const useLatestActivitiesQuery = () => {
  return useLPSuspenseQuery<GetLatestActivitiesResponse>(['activities-learner'], '/activities/latest');
};

export type GetLatestActivitiesResponse = {
  learnerId: string;
  learnerActivities: LearnerActivity[];
};

export type LearnerActivity = {
  actionScoreType: ActionScoreTypes;
  createdTime: string;
  score?: number;
};

export enum ActionScoreTypes {
  none = 'none',
  mandatoryModuleCompleted = 'mandatoryModuleCompleted',
  fullCampaignCompleted = 'fullCampaignCompleted',
  campaignCompletedWithHighEngagement = 'campaignCompletedWithHighEngagement',
  campaignCompletedWithHighKnowledge = 'campaignCompletedWithHighKnowledge',
  campaignCompletedOnDayWhenOpened = 'campaignCompletedOnDayWhenOpened',
  productivityCommentLeft = 'productivityCommentLeft',
  commentLeftAtModuleEnd = 'commentLeftAtModuleEnd',
  commentNotLeftAtModuleEnd = 'commentNotLeftAtModuleEnd',
  ratingLeft = 'ratingLeft',
  phishingSimulationReported = 'phishingSimulationReported',
  phishingSimulationClicked = 'phishingSimulationClicked',
  credentialsGivenInSimulation = 'credentialsGivenInSimulation',
  defenderTagGranted = 'defenderTagGranted',
  linkRepeatedlyClicked = 'linkRepeatedlyClicked',
  externalSpamReported = 'externalSpamReported',
  optionalTrainingCompleted = 'optionalTrainingCompleted',
  cyberbulletinWatched = 'cyberbulletinWatched',
  initialAssessmentCompleted = 'initialAssessmentCompleted',
  emailReportedAfterCompromised = 'emailReportedAfterCompromised',
  trainingIncompleteAfterDueDate = 'trainingIncompleteAfterDueDate',
  sbCompletionSuccess = 'sbCompletionSuccess',
  sbCompletionNeutral = 'sbCompletionNeutral',
  sbCompletionFail = 'sbCompletionFail',
  sbPhishingReportsSuccess = 'sbPhishingReportsSuccess',
  sbPhishingReportsNeutral = 'sbPhishingReportsNeutral',
  sbPhishingReportsFail = 'sbPhishingReportsFail',
  sbPhishingClicksSuccess = 'sbPhishingClicksSuccess',
  sbPhishingClicksNeutral = 'sbPhishingClicksNeutral',
  sbPhishingClicksFail = 'sbPhishingClicksFail',
  sbEngagementSuccess = 'sbEngagementSuccess',
  sbEngagementNeutral = 'sbEngagementNeutral',
  sbEngagementFail = 'sbEngagementFail',
  sbKnowledgeSuccess = 'sbKnowledgeSuccess',
  sbKnowledgeNeutral = 'sbKnowledgeNeutral',
  sbKnowledgeFail = 'sbKnowledgeFail',
}
