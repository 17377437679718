import { type FC } from 'react';
import { LeaderboardUI } from './LeaderboardUI';
import { withErrorBoundaries } from '../../lib/decorators/withErrorBoundaries';
import { CardErrorView } from '../ErrorView/CardErrorView';

const { LeaderboardUI_WithErrorBoundary } = withErrorBoundaries({ LeaderboardUI }, CardErrorView);

export const Leaderboard: FC = () => {
  return <LeaderboardUI_WithErrorBoundary />;
};
