import { type FC } from 'react';
import Background from '../../../assets/RobotAvatarBackground.png';
import styled from 'styled-components';
import { type CyberIQLevel, useCyberIQLevelsQuery } from '../../../apiHooks/useCyberIQLevelsQuery';

type RobotAvatarProps = {
  levelId: string | undefined;
};

const levelToIconMapper: Record<number, { top: string; left: string }> = {
  1: { top: '-5px', left: '-30px' },
  2: { top: '-20px', left: '-40px' },
  3: { top: '-20px', left: '-40px' },
  4: { top: '-20px', left: '-30px' },
  5: { top: '-20px', left: '-40px' },
};

export const RobotAvatar: FC<RobotAvatarProps> = ({ levelId }: RobotAvatarProps) => {
  const cyberIQLevelsQuery = useCyberIQLevelsQuery();
  const levels: CyberIQLevel[] = cyberIQLevelsQuery.data ?? [];
  const levelIndex: number = levels.findIndex((level: CyberIQLevel) => {
    return level.id === levelId;
  });
  const level: CyberIQLevel | undefined = levels[levelIndex];

  return (
    <Container>
      <ImageBackground src={Background}></ImageBackground>
      {level ? (
        <ImageAvatar
          src={level.assetUrl}
          alt="Avatar"
          top={levelToIconMapper[levelIndex + 1]?.top || '0px'}
          left={levelToIconMapper[levelIndex + 1]?.left || '0px'}
        />
      ) : (
        <NoAvatarMessage>No milestone passed yet</NoAvatarMessage>
      )}
    </Container>
  );
};

const Container = styled.div`
  width: 160px;
  height: 130px;
  position: relative;
  overflow: hidden;

  @media (max-width: 768px) {
    width: 150px;
  }
`;

const ImageBackground = styled.img`
  position: absolute;
  width: calc(100% - 30px);
  height: 100%;
  top: 0;
  left: 0;
  display: block;

  @media (max-width: 768px) {
    width: calc(100% - 20px);
  }
`;

const ImageAvatar = styled.img.withConfig({
  shouldForwardProp: (prop) => prop !== 'top' && prop !== 'left',
})<{ top: string; left: string }>`
  position: absolute;
  width: 200px;
  height: 200px;
  top: ${({ top }) => top};
  left: ${({ left }) => left};
`;

const NoAvatarMessage = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 14px;
  color: #555;
  text-align: center;
`;
