import { ManagerOverview } from '../components/ManagerOverview';
import { NamedContentEntryText } from '../components/ContentEntryText/NamedContentEntryText';
import type { MenuPage } from './MenuPage';

export const managerTopbarMenuPages: MenuPage[] = [
  {
    path: 'overview',
    name: <NamedContentEntryText container="span" refKey="menuSystem" subKey="overview" />,
    element: <ManagerOverview />,
  },
];
