import { Card } from '../../Card';
import styled, { useTheme } from 'styled-components';
import { type FC, useCallback, useMemo, useState } from 'react';
import { HamburgerMenuIcon } from '@radix-ui/react-icons';
import { useClickOutside } from '../../../lib/hooks/useOnClickOutside';
import { Icon, IconType } from '../../Props/Icon/Icon';
import type { Page } from '../TopBarUI';
import { MobileNavLinks } from './MobileNavLinks';
import { User } from './User';
import { UserAvatar } from './UserAvatar';
import { useMeQuery } from '../../../apiHooks/useMeQuery';
import { useAvatarQuery } from '../../../apiHooks/useAvatarQuery';
import { Button } from '../../Button';
import { NamedContentEntryText } from '../../ContentEntryText/NamedContentEntryText';
import { SwitchViewMenu, ViewOptions } from './SwitchViewMenu';
import { useNavigate } from 'react-router';
import SignOutIcon from '../assets/sign-out.svg';
import { LanguageSelector } from '../../LanguageSelector';

type MobileTopBarMenuProps = {
  pages: Page[];
  managerView?: boolean;
  onSignOut: () => void;
};

export const MobileTopBarMenu: FC<MobileTopBarMenuProps> = ({
  pages,
  managerView,
  onSignOut,
}: MobileTopBarMenuProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [switchViewMenuOpen, setSwitchViewMenuOpen] = useState<boolean>(false);
  const { data: meData } = useMeQuery();
  const { data: avatarData } = useAvatarQuery();
  const theme = useTheme();
  const navigate = useNavigate();

  const user = meData
    ? {
        name: meData.name || null,
        role: (meData.portalRole as 'learner' | 'manager') || null,
        avatar: avatarData?.avatar || null,
      }
    : {
        name: null,
        role: null,
        avatar: avatarData?.avatar || null,
      };

  const handleHide = useCallback(() => {
    setSwitchViewMenuOpen(false);
    setIsOpen(false);
  }, []);

  const menuItems = useMemo(
    () => [
      {
        element: <SignOutIcon />,
        label: <NamedContentEntryText container={'span'} refKey="menuSystem" subKey="signOut" />,
        action: () => onSignOut(),
      },
    ],
    [onSignOut],
  );

  const clickOutsideMenuRef = useClickOutside<HTMLDivElement>(handleHide);
  const clickOutsideSwitchViewMenuRef = useClickOutside<HTMLDivElement>(() => setSwitchViewMenuOpen(false));
  const showManageViewButton = useMemo(() => user.role === 'manager', [user.role]);

  const onSwitchView = (view: ViewOptions) => {
    if (view === ViewOptions.MANAGER && !managerView) {
      navigate('manager');
    }

    if (view === ViewOptions.USER && managerView) {
      navigate('..');
    }

    handleHide();
  };

  return (
    <MenuWrapper ref={clickOutsideMenuRef}>
      <HamburgerMenuIcon onClick={() => setIsOpen((prev) => !prev)} />
      <MenuContainer isOpen={isOpen}>
        <MenuTitle>
          <span>Menu</span>
          <CloseIconContainer onClick={handleHide}>
            <Icon icon={IconType.TIMES} />
          </CloseIconContainer>
        </MenuTitle>
        <MobileNavLinks handleHideMenu={handleHide} pages={pages} />
        <Separator />
        {showManageViewButton && (
          <>
            <ManagerViewContainer ref={clickOutsideSwitchViewMenuRef}>
              <SwitchViewButton
                backgroundColor={managerView ? theme.colors.managerPrimary : undefined}
                onClick={() => setSwitchViewMenuOpen((prev) => !prev)}
              >
                <ManagerViewButtonContainer>
                  <ManagerViewButtonText>
                    <ManagerViewButtonHighlight>
                      <NamedContentEntryText
                        refKey="menuSystem"
                        container="span"
                        subKey={managerView ? 'managerView' : 'userView'}
                      />
                    </ManagerViewButtonHighlight>
                  </ManagerViewButtonText>
                  <Icon icon={IconType.CHEVRON_DOWN} />
                </ManagerViewButtonContainer>
              </SwitchViewButton>
              <SwitchViewMenu selectedMenu={switchViewMenuOpen ? 'switchView' : undefined} onSwitch={onSwitchView} />
            </ManagerViewContainer>
            <Separator />
          </>
        )}
        <UserInfo>
          <UserAvatar user={user} />
          <User name={user.name} role={user.role} />
        </UserInfo>
        <Separator />
        <MenuSectionItems>
          <LanguageSelector />
          {menuItems.map(({ element, label, action }, idx) => (
            <MenuSectionItem key={`menuSectItem${idx}`} onClick={action}>
              {element}
              <span>{label}</span>
            </MenuSectionItem>
          ))}
        </MenuSectionItems>
      </MenuContainer>
    </MenuWrapper>
  );
};

const MenuWrapper = styled.div`
  width: 32px;
  height: 32px;
  display: none;
  align-items: center;
  justify-content: center;

  > svg {
    width: 100%;
    height: 100%;
    color: ${({ theme }) => theme.colors.strong};
    cursor: pointer;
  }

  @media (max-width: 768px) {
    display: flex;
  }
`;

const MenuContainer = styled(Card).withConfig({
  shouldForwardProp: (prop) => prop !== 'isOpen',
})<{ isOpen: boolean }>`
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  width: 280px;
  border-radius: 0;
  padding: 32px 24px;
  z-index: 99;
  display: flex;
  flex-direction: column;
  gap: 16px;
  transform: ${({ isOpen }) => (isOpen ? 'translateX(0)' : 'translateX(100%)')};
  transition: transform 250ms ease-in-out;
`;

const MenuTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-transform: uppercase;
  font-size: ${({ theme }) => theme.fontSizes.heading};
  color: ${({ theme }) => theme.colors.heading};
  font-weight: 600;
  margin-bottom: 24px;
`;

const CloseIconContainer = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const UserInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  font-size: ${({ theme }) => theme.fontSizes.medium};
`;

const Separator = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.tableBorder};
`;

const ManagerViewContainer = styled.div`
  position: relative;
`;

const ManagerViewButtonContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;

  svg path {
    stroke: #fff;
  }
`;

const ManagerViewButtonText = styled.span`
  margin-left: 10px;
`;

const ManagerViewButtonHighlight = styled.span`
  font-weight: 500;
`;

const MenuSectionItems = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const MenuSectionItem = styled.button`
  all: unset;
  cursor: pointer;
  display: grid;
  grid-template-columns: 16px max-content;
  gap: 8px;
  align-items: center;
  color: ${({ theme }) => theme.colors.heading};
  width: max-content;
  font-size: ${({ theme }) => theme.fontSizes.heading};
`;

const SwitchViewButton = styled(Button)`
  width: 100%;
`;
