import GreenArrowUp from '../../../../../assets/GreenArrowUp.svg';
import RedArrowDown from '../../../../../assets/RedArrowDown.svg';
import LineIcon from '../../../../../assets/Line.svg';

export const getRankArrow = (rank: number, previousRank: number) => {
  if (rank < previousRank) {
    return <GreenArrowUp />;
  } else if (rank > previousRank) {
    return <RedArrowDown />;
  }
  return <LineIcon />;
};
