import styled from 'styled-components';
import type { FC } from 'react';

type ProgressBarStep = {
  label: string;
};

type ProgressBarProps = {
  steps: ProgressBarStep[];
  value: number;
  showLabels?: boolean;
};

export const ProgressBar: FC<ProgressBarProps> = ({ steps, value, showLabels = true }) => {
  const progressPercentage: number = (value / (steps.length - 1)) * 100;
  const labelsAndPositions = steps.map((step, i) => ({
    label: step.label,
    posPct: (100 * i) / (steps.length - 1),
  }));

  return (
    <Container>
      {showLabels && (
        <Labels count={steps.length}>
          {labelsAndPositions.map(({ label, posPct }, index) => (
            <LabelText key={label} style={{ left: `${posPct}%` }} isActive={index === value}>
              {label}
            </LabelText>
          ))}
        </Labels>
      )}
      <Meter value={progressPercentage} />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const Labels = styled.div<{ count: number }>`
  width: 100%;
  position: relative;
  height: 20px;
  margin-bottom: 8px;
`;

const LabelText = styled.span<{ isActive: boolean }>`
  font-size: 14px;
  white-space: nowrap;
  position: absolute;
  display: block;
  transform: translateX(-50%);
  color: ${({ isActive }) => (isActive ? '#000' : '#888')};
  font-weight: ${({ isActive }) => (isActive ? 'bold' : 'normal')};

  &:first-child {
    transform: translateX(0);
  }
  &:last-child {
    transform: translateX(-100%);
  }
`;

const Meter = styled.div<{ value: number }>`
  width: 100%;
  background-color: #0002;
  height: 10px;
  position: relative;
  border-radius: 6px;

  &::before {
    content: ' ';
    height: 100%;
    border-radius: 6px;
    background: linear-gradient(to right, #7a8bfa, #4f3efc);
    width: ${({ value }) => value}%;
    position: absolute;
    transition: width 0.3s ease;
  }
`;
