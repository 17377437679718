import { useLPSuspenseQuery } from '../lib/hooks/useLPQuery';

export function useSecuritySnapshotQuery() {
  return useLPSuspenseQuery<SecuritySnapshot>(['security-snapshot'], '/me/securitySnapshot');
}
type SecuritySnapshot = {
  cyberIqPoints: number;
  learnerId: string;
  levelId: string;
  badges: string[];
};
