import { useContentEntryRefs, type ContentEntryRefs } from '../../../contentEntryRefs/contentEntryRefs';
import { useContentEntry } from './util/hooks/useContentEntry';

export function useNamedContentEntryText<RefKey extends keyof ContentEntryRefs>(
  props: { refKey: RefKey } & (ContentEntryRefs[RefKey] extends { nested: true; propSubKeyIndexes: infer P }
    ? { subKey: keyof P }
    : object),
): UseNamedContentEntryTextResult<RefKey> | undefined {
  const [contentEntryRef] = useContentEntryRefs([props.refKey]);
  const contentEntry = useContentEntry(contentEntryRef);
  if (contentEntry.entry) {
    if ('subKey' in props && contentEntryRef?.nested) {
      const returnValue = contentEntry.entry
        ? (contentEntry.entry.data.text[
            contentEntryRef.propSubKeyIndexes[props.subKey]
          ] as UseNamedContentEntryTextResult<RefKey>)
        : undefined;
      return returnValue;
    }
    return contentEntry.entry ? (contentEntry.entry.data.text as UseNamedContentEntryTextResult<RefKey>) : undefined;
  }
}
export type UseNamedContentEntryTextResult<RefKey extends keyof ContentEntryRefs> = ContentEntryRefs[RefKey] extends {
  nested: true;
}
  ? string
  : string[];
