import { CardStatus } from '../enums/CardStatus';

export const getCardStatus = (dueDate: Date) => {
  const now: Date = new Date();
  const timeDifference = dueDate.getTime() - now.getTime();
  const daysDifference = timeDifference / (1000 * 60 * 60 * 24);

  if (daysDifference < 2) {
    return CardStatus.urgent;
  } else if (daysDifference < 5) {
    return CardStatus.warning;
  } else {
    return CardStatus.normal;
  }
};
