import { useLPSuspenseQuery } from '../lib/hooks/useLPQuery';

export const useCompanyScoreTimelineQuery = () => {
  return useLPSuspenseQuery<GetCompanyScoreTimelineResponse>(
    ['activities-company'],
    '/activities/company/scoreTimeline',
  );
};

export type GetCompanyScoreTimelineResponse = CompanyScoreTimelineItem[];

export type CompanyScoreTimelineItem = {
  date: string;
  score: number;
};
