import { useEffect, useState, type FC } from 'react';
import styled from 'styled-components';
import { Table } from '../../../Table';
import { Modal } from '../../../Modal';
import { ManagerTeamTrainingCard } from '../ManagerTeamTrainingCard';
import { NamedContentEntryText } from '../../../ContentEntryText/NamedContentEntryText';
import DownloadButton from '../../../Button/DownloadButton/DownloadButton';
import type { ManagerTeamUser } from '../../../../lib/types/ManagerTeamUser';
import { useTeamTrainingCompleationData } from './hooks/useTeamTrainingCompleationData';
import { useManagerTeamQuery } from '../../../../apiHooks/useManagerTeamQuery';
import { convertToCSV } from '../../../Button/DownloadButton/utils/convertToCSV';
import { downloadCSV } from '../../../Button/DownloadButton/utils/downloadCSV';

type TrainingCompletionModalProps = {
  isOpen: boolean;
  onClose: () => void;
  nudgeAllLearners: (trainingId: string) => void;
  statsCard?: {
    totalLearners: number;
    learnersCompletedTotal: number;
    estimatedCompletionTime: number;
    title: string;
    dueDate: string;
  };
};

export enum TeamDetailsTabs {
  NonCompleters = 'nonCompleters',
  Completed = 'completed',
}

export const TrainingCompletionModal: FC<TrainingCompletionModalProps> = ({
  isOpen,
  onClose,
  statsCard,
}: TrainingCompletionModalProps) => {
  const mockData = useManagerTeamQuery();
  const { columns } = useTeamTrainingCompleationData();

  const [selectedTab, setSelectedTab] = useState<TeamDetailsTabs>(TeamDetailsTabs.NonCompleters);
  const [learnerList, setLearnerList] = useState<ManagerTeamUser[]>(mockData.filter((user) => !user.trainingCompleted));

  useEffect(() => {
    setLearnerList(
      mockData.filter((user) =>
        selectedTab === TeamDetailsTabs.NonCompleters ? !user.trainingCompleted : user.trainingCompleted,
      ),
    );
  }, [selectedTab, mockData]);

  const handleExportUsers = async () => {
    if (learnerList) {
      const csv = convertToCSV(learnerList);
      downloadCSV(csv, 'team-activity-log.csv');
    }
  };

  return (
    <Modal title="Completion Status" isOpen={isOpen} onClose={onClose}>
      <ModalContainer>
        <Content>
          <ManagerTeamTrainingCard
            title={statsCard?.title || ''}
            dueDate={statsCard?.dueDate || ''}
            totalLearners={statsCard?.totalLearners || 0}
            learnersCompletedTotal={statsCard?.learnersCompletedTotal || 0}
            estimatedCompletionTime={statsCard?.estimatedCompletionTime || 0}
            nudgeActionText={
              <NamedContentEntryText
                container="span"
                refKey="managerTrainingCompletionDialog"
                subKey="nudgeAllNonCompleters"
              />
            }
          />
          <TabUtilityContainer>
            <TabFilterContainer>
              <TabSection onClick={() => setSelectedTab(TeamDetailsTabs.NonCompleters)}>
                <TabSectionTitle isSelected={selectedTab === TeamDetailsTabs.NonCompleters}>
                  <NamedContentEntryText
                    container="span"
                    refKey="managerTrainingCompletionDialog"
                    subKey="nonCompleters"
                  />
                </TabSectionTitle>
                <Delimiter isSelected={selectedTab === TeamDetailsTabs.NonCompleters} />
              </TabSection>
              <TabSection onClick={() => setSelectedTab(TeamDetailsTabs.Completed)}>
                <TabSectionTitle isSelected={selectedTab === TeamDetailsTabs.Completed}>
                  <NamedContentEntryText
                    container="span"
                    refKey="managerTeamCyberSecurityTraining"
                    subKey="completed"
                  />
                </TabSectionTitle>
                <Delimiter isSelected={selectedTab === TeamDetailsTabs.Completed} />
              </TabSection>
            </TabFilterContainer>
            <DownloadSection>
              <DownloadButton
                onClick={handleExportUsers}
                text={<NamedContentEntryText container="span" refKey="table" subKey="exportActivityLogCsv" />}
              ></DownloadButton>
              <Delimiter />
            </DownloadSection>
          </TabUtilityContainer>
          <Table data={learnerList} columns={columns} />
        </Content>
      </ModalContainer>
    </Modal>
  );
};

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  min-height: 700px;
  width: 924px;
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 16px;
`;

const TabUtilityContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 16px;
`;

const TabFilterContainer = styled.div`
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const TabSection = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
`;

const DownloadSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 70%;
  align-items: end;
`;

const TabSectionTitle = styled.div<{ isSelected: boolean }>`
  font-weight: ${({ isSelected }) => (isSelected ? 'bold' : 'normal')};
`;

const Delimiter = styled.div<{ isSelected?: boolean }>`
  height: 2px;
  background-color: ${({ isSelected }) => (isSelected ? '#5170EF' : '#B6BEDC')};
  margin-top: 5px;
  width: 100%;
`;
