import { type FC } from 'react';
import styled from 'styled-components';
import GoldMedal from '../../assets/GoldMedalIcon.svg';
import SilverMedal from '../../assets/SilverMedalIcon.svg';
import BronzeMedal from '../../assets/BronzeMedalIcon.svg';
import { getRankArrow } from './utils/getRankArrow';

type UserRankProps = {
  rank: number;
  previousRank: number;
};

export const UserRank: FC<UserRankProps> = ({ rank, previousRank }) => {
  const getUserRank = () => {
    if (rank === 1) {
      return <GoldMedal />;
    }
    if (rank === 2) {
      return <SilverMedal />;
    }
    if (rank === 3) {
      return <BronzeMedal />;
    }

    return <DefaultRank>{rank}</DefaultRank>;
  };

  return (
    <RankContainer>
      <RankText>{getUserRank()}</RankText>
      {getRankArrow(rank, previousRank)}
    </RankContainer>
  );
};

const RankContainer = styled.div`
  display: grid;
  grid-template-columns: 32px 16px;
  align-items: center;
  gap: 16px;

  @media (max-width: 768px) {
    align-self: flex-start;
    padding: 4px 0;
    gap: 8px;
  }
`;

const RankText = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.medium};
  color: ${({ theme }) => theme.colors.normal};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DefaultRank = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: ${({ theme }) => theme.colors.pageBackground};
`;
