import { invert, darken, desaturate, lighten } from 'polished';
import { pipeInto } from 'ts-functional-pipe';
export function getTheme({ rtl, colorTheme }: { rtl: boolean; colorTheme: 'light' | 'dark' }) {
  const theme = {
    rtl,
    colors: getColors(colorTheme),
    fontSizes: {
      small: '10px',
      normal: '12px',
      heading: '16px',
      medium: '14px',
      large: '20px',
      extraLarge: '24px',
    },
    borderRadius: {
      small: '6px',
      normal: '8px',
      medium: '10px',
      large: '16px',
      extraLarge: '24px',
    },
    buttonSize: {
      small: '8px 16px',
      medium: '10px 20px',
      large: '12px 24px',
    },
    shadow: {
      light: '0 4px 8px rgba(0, 0, 0, 0.1)',
    },
  };
  return theme;
}

type Colors = {
  pageBackground: string;
  panelBackground: string;
  insetPanelBackground: string;
  normal: string;
  heading: string;
  strong: string;
  anchor: string;
  primary: string;
  primaryContrast: string;
  error: string;
  urgent: string;
  urgentBackground: string;
  waningText: string;
  warningBackground: string;
  tableBorder: string;
  userLevel: string;
  trainingCardBackground: string;
  trainingCardButton: string;
  managerPrimary: string;
  statusDanger: string;
};
function getColors(colorTheme: 'light' | 'dark') {
  return colorTheme === 'dark'
    ? ({
        pageBackground: pipeInto('#EEF1F9', invert, darken(0.1)),
        panelBackground: '#252525',
        insetPanelBackground: pipeInto('#eef1f9', invert, darken(0.04)),
        normal: pipeInto('#596180', invert, desaturate(0.2), lighten(0.1)),
        heading: invert('#292B33'),
        strong: invert('#151515'),
        anchor: '#5170ef',
        primary: '#4c5aff',
        managerPrimary: '#FF6F5A',
        primaryContrast: '#FFFFFF',
        error: invert('#933547'),
        urgent: pipeInto('#C11414', lighten(0.1)),
        urgentBackground: invert('#FFF1F1'),
        waningText: invert('#B1410C'),
        warningBackground: invert('#FFF9EB'),
        tableBorder: pipeInto('#B6BEDC', invert, desaturate(0.3)),
        userLevel: invert('#5601D6'),
        trainingCardBackground: invert('#F7F8FA'),
        trainingCardButton: invert('#DDE7FF'),
        statusDanger: invert('#E93354'),
      } satisfies Colors)
    : ({
        pageBackground: '#EEF1F9',
        panelBackground: '#FFFFFF',
        insetPanelBackground: '#eef1f9',
        normal: '#596180',
        heading: '#292B33',
        strong: '#151515',
        anchor: '#5170ef',
        primary: '#4c5aff',
        managerPrimary: '#FF6F5A',
        primaryContrast: '#FFFFFF',
        error: '#933547',
        urgent: '#C11414',
        urgentBackground: '#FFF1F1',
        waningText: '#B1410C',
        warningBackground: '#FFF9EB',
        tableBorder: '#B6BEDC',
        userLevel: '#5601D6',
        trainingCardBackground: '#F7F8FA',
        trainingCardButton: '#DDE7FF',
        statusDanger: '#E93354',
      } satisfies Colors);
}
