import { type FC, useMemo } from 'react';
import { Card } from '../Card';
import { NamedContentEntryText } from '../ContentEntryText/NamedContentEntryText';
import { Table } from '../Table';
import {
  ActionScoreTypes,
  type LearnerActivity,
  useLatestActivitiesQuery,
} from '../../apiHooks/useLatestActivitiesQuery';
import type { Column } from '../Table/Table';
import { useLanguageContext } from '../../lib/hooks/useLanguageContext';
import { Coin } from '../Props';
import { ActivityOverviewChart } from './components/ActivityOverviewChart/ActivityOverviewChart';
import styled from 'styled-components';
import { useCompanyScoreTimelineQuery } from '../../apiHooks/useCompanyScoreTimelineQuery';

export const ActivityOverviewWidget: FC = () => {
  const { data: latestActivitiesResponse } = useLatestActivitiesQuery();
  const { data: companyAverageResponse } = useCompanyScoreTimelineQuery();

  const latestActivities: LearnerActivity[] = useMemo(
    () =>
      latestActivitiesResponse?.learnerActivities.filter(
        (activity: LearnerActivity) => activity.actionScoreType !== ActionScoreTypes.none,
      ) || [],
    [latestActivitiesResponse?.learnerActivities],
  );

  const sortedActivities = useMemo(() => {
    return [...latestActivities].sort((a, b) => new Date(a.createdTime).getTime() - new Date(b.createdTime).getTime());
  }, [latestActivities]);

  const sortedCompanyAverage = useMemo(() => {
    return [...companyAverageResponse].sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
  }, [companyAverageResponse]);

  const [langCode] = useLanguageContext();

  const columns: Column<LearnerActivity>[] = [
    {
      header: <NamedContentEntryText container="label" refKey="activityOverview" subKey="recentActivity" />,
      valueSelector: (activity: LearnerActivity) => (
        <NamedContentEntryText container="span" refKey="activityStatus" subKey={activity.actionScoreType} />
      ),
    },
    {
      header: <NamedContentEntryText container="label" refKey="activityOverview" subKey="date" />,
      valueSelector: (activity: LearnerActivity) =>
        new Intl.DateTimeFormat(langCode).format(new Date(activity.createdTime)),
      width: '100px',
    },
    {
      header: <NamedContentEntryText container="label" refKey="activityOverview" subKey="points" />,
      valueSelector: (activity: LearnerActivity) => activity.score && <Coin value={activity.score} />,
      width: 'min-content',
      alignment: 'right',
    },
  ];

  return (
    <Card title={<NamedContentEntryText container={'span'} refKey="sectionTitles" subKey="activityOverview" />}>
      <ActivityOverviewChartContainer>
        <ActivityOverviewChart data={sortedActivities} companyAverage={sortedCompanyAverage} />
      </ActivityOverviewChartContainer>
      <Table data={latestActivities} columns={columns} />
    </Card>
  );
};

const ActivityOverviewChartContainer = styled.div`
  padding-left: 4px;
`;
