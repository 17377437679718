import { type FC } from 'react';
import styled from 'styled-components';
import { Icon, IconType } from '../Icon/Icon';

type CoinProps = {
  value: number;
};

export const Coin: FC<CoinProps> = ({ value }: CoinProps) => {
  const isPositive: boolean = value >= 0;
  const formattedValue = `${isPositive ? '+' : ''}${value}`;

  return (
    <StyledCoin positive={`${isPositive}`}>
      <ValueText positive={`${isPositive}`}>{formattedValue}</ValueText>
      <Icon icon={IconType.COIN} width={20} height={20} />
    </StyledCoin>
  );
};

const StyledCoin = styled.label.withConfig({
  shouldForwardProp: (prop) => prop !== 'positive',
})<{ positive: string }>`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 4px 8px;
  border-radius: ${({ theme }) => theme.borderRadius.extraLarge};
  border: 1px solid ${({ positive }) => (positive === 'true' ? '#B6E2C6' : '#F5C6C6')};
  background-color: ${({ positive }) => (positive === 'true' ? '#ECFDF5' : '#FFF5F5')};
`;

const ValueText = styled.span.withConfig({
  shouldForwardProp: (prop) => prop !== 'positive',
})<{ positive: string }>`
  color: ${({ positive }) => (positive === 'true' ? '#3AA66A' : '#C94A4A')};
  font-size: ${({ theme }) => theme.fontSizes.medium};
  font-weight: 500;
`;
