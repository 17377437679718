import { useEffect, useState } from 'react';

export const useKey = (keyPredicate: (key: string) => boolean, cb?: () => void): boolean => {
  const [keyDown, setKeyDown] = useState(false);

  useEffect(() => {
    const keydownEventHandler = (e: KeyboardEvent): void => {
      if (keyPredicate(e.key)) {
        setKeyDown(true);
        if (cb) {
          cb();
        }
      }
    };
    const keyupEventHandler = (e: KeyboardEvent): void => {
      if (keyPredicate(e.key)) {
        setKeyDown(false);
      }
    };

    window.addEventListener('keydown', keydownEventHandler);
    window.addEventListener('keyup', keyupEventHandler);

    return () => {
      window.removeEventListener('keydown', keydownEventHandler);
      window.removeEventListener('keyup', keyupEventHandler);
    };
  }, [keyPredicate, cb]);

  return keyDown;
};
