import { useLPSuspenseQuery } from '../lib/hooks/useLPQuery';

export enum ScoreItemIdentifier {
  COMPLETION = 'Completion',
  PHISHING_REPORTS = 'PhishingReports',
  PHISHING_CLICKS = 'PhishingClicks',
  ENGAGEMENT = 'Engagement',
  KNOWLEDGE = 'Knowledge',
}

export type ScoreItem = {
  id: number;
  success: number;
  fail: number;
  neutral: number;
  name: string;
  description: string;
  identifier: ScoreItemIdentifier;
  assetUrl: string;
  pointsSuccess: number;
  pointsNeutral: number;
  pointsFail: number;
};

export function useScoreBreakdownsQuery() {
  return useLPSuspenseQuery<ScoreItem[]>(['leaderboards/scoreBreakdowns'], '/scoreBreakdowns');
}
