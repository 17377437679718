import styled from 'styled-components';
import { type FC, useMemo } from 'react';
import { RobotAvatar } from '../Props/RobotAvatar/RobotAvatar';
import { SplitButton } from '../Button';
import {
  SteppingProgressBar,
  type SteppingProgressBarStep,
} from '../ProgressBar/SteppingProgressBar/SteppingProgressBar';
import { Icon, IconType } from '../Props/Icon/Icon';
import { useSecuritySnapshotQuery } from '../../apiHooks/useSecuritySnapshotQuery';
import { useMeQuery } from '../../apiHooks/useMeQuery';
import { Card } from '../Card';
import { type CyberIQLevel, useCyberIQLevelsQuery } from '../../apiHooks/useCyberIQLevelsQuery';
import type { SnapshotLevel } from '../../contentEntryRefs/contentEntryRefs';
import { BadgeList } from '../Props/BadgeList/BadgeList';
import { NamedContentEntryText } from '../ContentEntryText/NamedContentEntryText';
import ExternalLinkButton from '../Button/ExternalLinkButton/ExternalLinkButton';

export const SecuritySnapshotUI: FC = () => {
  const { data: securitySnapshotData } = useSecuritySnapshotQuery();
  const { data: cyberIqLevelsData } = useCyberIQLevelsQuery();
  const { data: meData } = useMeQuery();

  const currentLevelIndex: number = useMemo(
    () =>
      cyberIqLevelsData.findIndex((level) => {
        return level.id === securitySnapshotData.levelId;
      }),
    [cyberIqLevelsData, securitySnapshotData.levelId],
  );

  const stepperSteps: SteppingProgressBarStep[] = useMemo(
    () =>
      cyberIqLevelsData.map((level, index) => {
        return {
          value: index,
          label: level.id,
        };
      }),
    [cyberIqLevelsData],
  );

  const dataLevel: CyberIQLevel | undefined = cyberIqLevelsData[currentLevelIndex];
  const currentLevelLabel: SnapshotLevel | undefined = dataLevel?.id;
  const securitySnapshotLevelKey = currentLevelLabel
    ? (`securitySnapshotLevels${currentLevelLabel}` as const)
    : undefined;

  return (
    <Card title={<NamedContentEntryText container={'span'} refKey="sectionTitles" subKey="securitySnapshot" />}>
      <CardContainer>
        <AvatarContainer>
          <RobotAvatar levelId={currentLevelLabel} />
        </AvatarContainer>
        <NameContainer>
          <UsernameField>{meData?.name}</UsernameField>
        </NameContainer>
        <LevelContainer>
          <SplitButton
            leftElement={
              securitySnapshotLevelKey && (
                <NamedContentEntryText container={'span'} refKey={securitySnapshotLevelKey} subKey="levelN" />
              )
            }
            rightElement={
              securitySnapshotLevelKey && (
                <LevelName>
                  <NamedContentEntryText container={'span'} refKey={securitySnapshotLevelKey} subKey="levelName" />
                </LevelName>
              )
            }
          />
        </LevelContainer>
        <DescriptionContainer>
          {securitySnapshotLevelKey ? (
            <>
              <NamedContentEntryText container={'div'} refKey={securitySnapshotLevelKey} subKey="bodyHeading" asHtml />
              <NamedContentEntryText container={'div'} refKey={securitySnapshotLevelKey} subKey="bodyMain" asHtml />
            </>
          ) : null}
        </DescriptionContainer>
        <BadgesContainer>
          <BadgeList
            badgeIds={securitySnapshotData.badges}
            max={5}
            renderLinks={() => (
              <ExternalLinkButton
                href="/"
                text={securitySnapshotData.badges.length ? 'Earn more' : 'How to earn'}
                className="earn-badges"
              />
            )}
          />
        </BadgesContainer>
        <ScoreContainer>
          <IQPointsContainer>
            <IQPointsField>
              <Icon icon={IconType.COIN} />
              <IQPointsLabel>
                {<NamedContentEntryText container={'span'} refKey="securitySnapshot" subKey="cyberiqPoints" />}:
              </IQPointsLabel>
            </IQPointsField>
            <IQPointsValue>
              <b>{securitySnapshotData.cyberIqPoints.toLocaleString()}</b> / {dataLevel?.maxPoints}
            </IQPointsValue>
          </IQPointsContainer>
        </ScoreContainer>
        <ProgressContainer>
          <SteppingProgressBar steps={stepperSteps} value={currentLevelIndex} />
        </ProgressContainer>
      </CardContainer>
    </Card>
  );
};

const CardContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.insetPanelBackground};
  border-radius: ${({ theme }) => theme.borderRadius.extraLarge};
  display: grid;
  padding: 24px;
  grid-template-columns: 1fr 4fr;
  grid-template-rows: auto auto;

  > div {
    display: flex;
  }

  @media (max-width: 768px) {
    border-radius: 0;
    grid-template-columns: 1fr 2fr;
    grid-template-rows: repeat(5, auto);
    padding: 20px;
    margin: 0 -16px;
  }
`;

const AvatarContainer = styled.div`
  grid-column: 1;
  grid-row: 1 / span 3;
  height: 100%;
  width: fit-content;

  @media (max-width: 768px) {
    grid-column: 1;
    grid-row: 1 / span 2;
  }
`;

const NameContainer = styled.div`
  grid-column: 2;
  grid-row: 1;
  width: 50%;
  justify-content: left;

  @media (max-width: 768px) {
    grid-column: 2;
    grid-row: 1;
    width: 100%;
  }
`;

const LevelContainer = styled.div`
  grid-column: 2;
  grid-row: 1;
  justify-self: end;
  width: 50%;
  justify-content: right;

  @media (max-width: 768px) {
    grid-column: 2;
    grid-row: 2;
    width: 100%;
    justify-self: left;
    justify-content: left;
  }
`;

const LevelName = styled.span`
  text-transform: uppercase;
`;

const DescriptionContainer = styled.div`
  grid-column: 2;
  grid-row: 2;
  margin-top: 20px;
  flex-direction: column;
  font-size: ${({ theme }) => theme.fontSizes.normal};
  letter-spacing: 0.24px;

  @media (max-width: 768px) {
    grid-column: 1 / span 2;
    grid-row: 3;
  }
`;

const BadgesContainer = styled.div`
  grid-column: 2;
  grid-row: 3;
  width: 70%;

  @media (max-width: 768px) {
    grid-column: 1 / span 2;
    grid-row: 6;
    width: 100%;
    margin-top: 16px;
  }
`;

const ScoreContainer = styled.div`
  grid-column: 2;
  grid-row: 3;
  justify-self: end;
  width: 30%;

  @media (max-width: 768px) {
    grid-column: 1 / span 2;
    grid-row: 4;
    width: 100%;
    margin-top: 20px;
  }
`;

const ProgressContainer = styled.div`
  grid-column: 1 / span 2;
  grid-row: 4;
  margin-top: 16px;

  @media (max-width: 768px) {
    grid-column: 1 / span 2;
    grid-row: 5;
  }
`;

const UsernameField = styled.span`
  margin: auto auto auto 0;
  font-weight: 600;
  font-size: 20px;
  color: ${({ theme }) => theme.colors.strong};
`;

const IQPointsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 0 0 auto;

  @media (max-width: 768px) {
    width: 100%;
    flex-direction: row;
  }
`;

const IQPointsField = styled.div`
  display: flex;
  align-items: center;
  font-weight: 500;
  margin-bottom: 8px;

  @media (max-width: 768px) {
    margin-bottom: 0;
    display: flex;
    align-items: center;
  }
`;

const IQPointsLabel = styled.label`
  margin-left: 8px;
  font-size: ${({ theme }) => theme.fontSizes.normal};

  @media (max-width: 768px) {
    margin-right: 10px;
  }
`;

const IQPointsValue = styled.div`
  text-align: right;
  font-size: ${({ theme }) => theme.fontSizes.medium};

  > b {
    color: ${({ theme }) => theme.colors.heading};
  }

  @media (max-width: 768px) {
    display: flex;
    align-items: center;
  }
`;
