import type { FC } from 'react';
import styled from 'styled-components';
import { useTransitionVisibility } from '../../../lib/hooks/useTransitionVisibility';
import { NamedContentEntryText } from '../../ContentEntryText/NamedContentEntryText';

type SwitchViewMenuProps = {
  selectedMenu: string | undefined;
  onSwitch: (view: ViewOptions) => void;
};

export enum ViewOptions {
  MANAGER,
  USER,
}

export const SwitchViewMenu: FC<SwitchViewMenuProps> = ({ selectedMenu, onSwitch }: SwitchViewMenuProps) => {
  const visible = selectedMenu === 'switchView';
  const { ref, isMounted } = useTransitionVisibility<HTMLDivElement>(visible);

  return (
    isMounted && (
      <MenuContainer $isHidden={!visible} ref={ref}>
        <MenuItem onClick={() => onSwitch(ViewOptions.MANAGER)}>
          <NamedContentEntryText refKey="menuSystem" container="span" subKey="managerView" />
        </MenuItem>
        <MenuItem onClick={() => onSwitch(ViewOptions.USER)}>
          <NamedContentEntryText refKey="menuSystem" container="span" subKey="userView" />
        </MenuItem>
      </MenuContainer>
    )
  );
};

const MenuContainer = styled.div<{
  $isHidden: boolean;
}>`
  opacity: ${({ $isHidden: isHidden }) => (isHidden ? 0 : 1)};
  transform: ${({ $isHidden: isHidden }) => (isHidden ? 'translateY(-5px)' : 'translateY(0)')};
  transition:
    opacity 0.3s,
    transform 0.3s;
  pointer-events: ${({ $isHidden: isHidden }) => (isHidden ? 'none' : 'auto')};
  width: 175px;
  position: absolute;
  left: 0;
  top: calc(100% + 3px);
  background-color: ${({ theme }) => theme.colors.panelBackground};
  border-radius: ${({ theme }) => theme.borderRadius.normal};
  box-shadow: ${({ theme }) => theme.shadow.light};
  border: 1px solid ${({ theme }) => theme.colors.tableBorder};
  z-index: 99;

  @media (max-width: 768px) {
    width: 232px;
  }
`;

const MenuItem = styled.div`
  width: 100%;
  padding: 8px 16px;
  cursor: pointer;
  font-size: ${({ theme }) => theme.fontSizes.medium};

  &:hover {
    background-color: ${({ theme }) => theme.colors.insetPanelBackground};
  }

  &:first-child {
    border-top-left-radius: ${({ theme }) => theme.borderRadius.normal};
    border-top-right-radius: ${({ theme }) => theme.borderRadius.normal};
  }

  &:last-child {
    border-bottom-left-radius: ${({ theme }) => theme.borderRadius.normal};
    border-bottom-right-radius: ${({ theme }) => theme.borderRadius.normal};
  }
`;
