import { type FontFaceDescriptor } from '../../lib/hooks/useFontFaces/FontFaceDescriptor';
import Poppins400 from './assets/fonts/Poppins/Poppins-Regular.ttf';
import Poppins500 from './assets/fonts/Poppins/Poppins-Medium.ttf';
import Poppins600 from './assets/fonts/Poppins/Poppins-SemiBold.ttf';
import Poppins700 from './assets/fonts/Poppins/Poppins-Bold.ttf';
import Poppins300 from './assets/fonts/Poppins/Poppins-Thin.ttf';
import RobotoMono400 from './assets/fonts/RobotoMono/RobotoMono-Regular.ttf';

export const fontFaces: FontFaceDescriptor[] = [
  {
    family: 'Poppins',
    src: Poppins300,
    format: 'opentype',
    weight: '300',
    style: 'normal',
  },
  {
    family: 'Poppins',
    src: Poppins400,
    format: 'opentype',
    weight: '400',
    style: 'normal',
  },
  {
    family: 'Poppins',
    src: Poppins500,
    format: 'opentype',
    weight: '500',
    style: 'normal',
  },
  {
    family: 'Poppins',
    src: Poppins600,
    format: 'opentype',
    weight: '600',
    style: 'normal',
  },
  {
    family: 'Poppins',
    src: Poppins700,
    format: 'opentype',
    weight: '700',
    style: 'normal',
  },
  {
    family: 'Roboto Mono',
    src: RobotoMono400,
    format: 'opentype',
    weight: '400',
    style: 'normal',
  },
];
