import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from 'chart.js';
import type { LearnerActivity } from '../../../../apiHooks/useLatestActivitiesQuery';
import BankCardSearchIcon from '../../../../assets/BankCardSearch.svg';
import styled from 'styled-components';
import { NamedContentEntryText } from '../../../ContentEntryText/NamedContentEntryText';
import { useNamedContentEntryText } from '../../../../lib/hooks/contentEntry/useNamedContentEntryText';
import type { CompanyScoreTimelineItem } from '../../../../apiHooks/useCompanyScoreTimelineQuery';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Filler, Legend);

type ActivityOverviewChartProps = {
  data: LearnerActivity[];
  companyAverage: CompanyScoreTimelineItem[];
};

const getBasePowerOfTen = (value: number) => Math.pow(10, Math.floor(Math.log10(value)));

const getNextStep = (value: number, factors = [1, 2, 5, 10]) => {
  const base = getBasePowerOfTen(value);
  return base * (factors.find((factor) => factor * base >= value) || 1);
};

const computeMaxY = (data: number[]) => {
  const maxValue = Math.max(...data.map((item) => item || 0));
  return getNextStep(maxValue);
};

const formatLabels = (dates: string[], addYearToLabels: boolean, showDays: boolean) => {
  return dates.map((item) => {
    const date = new Date(item);
    if (showDays) {
      return date.toLocaleDateString(undefined, { day: 'numeric', month: 'short' });
    }
    const month = date.toLocaleDateString(undefined, { month: 'short' });
    const year = addYearToLabels ? `'${date.getFullYear().toString().slice(-2)}` : '';
    return `${month}${year}`;
  });
};

const filterLabels = (allLabels: string[], maxLabels: number) => {
  const step = allLabels.length <= maxLabels ? 1 : Math.ceil(allLabels.length / maxLabels);
  const areAllLabelsTheSame = allLabels.every((label) => label === allLabels[0]);

  return areAllLabelsTheSame
    ? allLabels.map((_, index) => (index === Math.floor(allLabels.length / 2) ? allLabels[index] : ''))
    : allLabels.filter((_, index) => index % step === 0);
};

const createBackgroundSectionsPlugin = (yTickCount: number) => ({
  id: 'backgroundSections',
  beforeDraw: (chart: ChartJS) => {
    const { ctx, chartArea } = chart;
    const { top, bottom, left, right } = chartArea;

    if (!yTickCount || yTickCount < 2) {
      return;
    }

    ctx.save();

    const sectionsCount = yTickCount;
    const sectionHeight = (bottom - top) / sectionsCount;

    ctx.strokeStyle = '#EEF1F9';
    ctx.lineWidth = 1;

    for (let i = 0; i < sectionsCount; i++) {
      ctx.fillStyle = i % 2 === 0 ? '#F7F8FA' : '#FFFFFF';
      ctx.fillRect(left, top + sectionHeight * i, right - left, sectionHeight);
      ctx.beginPath();
      ctx.moveTo(left, top + sectionHeight * i);
      ctx.lineTo(right, top + sectionHeight * i);
      ctx.stroke();
    }

    ctx.restore();
  },
});

const ActivityOverviewChartImpl = ({ data, companyAverage }: ActivityOverviewChartProps) => {
  const learnerPointsText = useNamedContentEntryText({ refKey: 'activityOverview', subKey: 'yourPoints' });
  const companyAverageText = useNamedContentEntryText({ refKey: 'activityOverview', subKey: 'companyAverage' });

  const companyAverageLocal = companyAverage.map((item) => item.score);
  const maxY =
    companyAverageLocal.length === 0 && data.length === 0
      ? 100
      : computeMaxY([...companyAverageLocal, ...data.map((item) => item.score || 0)]);
  const userPoints = data.map((item) => item.score || 0);

  const uniqueMonths = data.length
    ? new Set(
        data.map((item) => `${new Date(item.createdTime).getFullYear()}-${new Date(item.createdTime).getMonth()}`),
      )
    : new Set(companyAverage.map((item) => `${new Date(item.date).getFullYear()}-${new Date(item.date).getMonth()}`));

  const uniqueYears = data.length
    ? new Set(data.map((item) => new Date(item.createdTime).getFullYear()))
    : new Set(companyAverage.map((item) => new Date(item.date).getFullYear()));

  const addYearToLabels = uniqueYears.size > 1;
  const showDays = uniqueMonths.size === 1;

  const allLabels = formatLabels(
    data.length ? data.map((item) => item.createdTime) : companyAverage.map((item) => item.date),
    addYearToLabels,
    showDays,
  );
  const labels = filterLabels(allLabels, Math.min(6, Math.max(3, allLabels.length)));

  const yTickCount = Math.max(3, Math.min(5, Math.floor(maxY / (maxY / 3))));

  const chartData = {
    labels: labels,
    datasets: [
      {
        label: learnerPointsText,
        data: userPoints,
        borderColor: '#00A86B',
        backgroundColor: '#00A86B',
        borderWidth: 2,
        fill: false,
        tension: 0.4,
        pointRadius: 0,
      },
      {
        label: companyAverageText,
        data: companyAverageLocal,
        borderColor: '#89CFF0',
        backgroundColor: '#89CFF0',
        borderWidth: 2,
        borderDash: [10, 10],
        fill: false,
        tension: 0.4,
        pointRadius: 0,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: 'bottom' as const,
        align: 'start' as const,
        labels: {
          usePointStyle: true,
          pointStyle: 'circle',
          boxWidth: 7,
          boxHeight: 7,
          padding: 15,
          color: '#596180',
          font: { size: 12, lineHeight: 0.5 },
        },
      },
    },
    scales: {
      x: {
        grid: { display: false },
        border: { display: false },
        ticks: {
          color: '#888EAA',
        },
      },
      y: {
        beginAtZero: true,
        max: maxY,
        ticks: {
          stepSize: maxY / yTickCount,
          font: { family: 'Roboto Mono', size: 12 },
          color: '#888EAA',
          callback: (tickValue: string | number) =>
            new Intl.NumberFormat('en', { notation: 'compact' }).format(Number(tickValue)),
        },
        grid: { display: false },
        border: { display: false },
      },
    },
    layout: {
      padding: { bottom: 10, left: 10 },
    },
  };

  return (
    <div style={{ width: '100%', height: '200px' }}>
      <Line data={chartData} options={options} plugins={[createBackgroundSectionsPlugin(yTickCount)]} />
    </div>
  );
};

export const ActivityOverviewChart = ({ data, companyAverage }: ActivityOverviewChartProps) => {
  return (
    <ActivityOverviewChartInnerContainer>
      {companyAverage.length === 0 && data.length === 0 && (
        <ActivityOverviewChartZeroStateOverlay>
          <BankCardSearchIcon />
          <NamedContentEntryText container={'span'} refKey="chart" subKey="chartNoData" />
        </ActivityOverviewChartZeroStateOverlay>
      )}
      <ActivityOverviewChartImpl data={data} companyAverage={companyAverage} />
    </ActivityOverviewChartInnerContainer>
  );
};

const ActivityOverviewChartInnerContainer = styled.div`
  position: relative;
`;

const ActivityOverviewChartZeroStateOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  z-index: 1;
  pointer-events: none;
  padding-bottom: 48px;
  font-size: ${({ theme }) => theme.fontSizes.medium};
  font-weight: normal;

  > svg {
    width: 48px;
    height: 48px;
  }
`;
