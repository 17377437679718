import type { FC, ReactNode } from 'react';
import styled from 'styled-components';
import { type CyberIQBadge, useCyberIQBadgesQuery } from '../../../apiHooks/useCyberIQBadgesQuery';
import { Icon, IconType } from '../Icon/Icon';
import { NamedContentEntryText } from '../../ContentEntryText/NamedContentEntryText';

type BadgeListProps = {
  badgeIds: string[];
  max?: number;
  renderLinks?: () => ReactNode;
};

export const BadgeList: FC<BadgeListProps> = ({ badgeIds, max = 10, renderLinks }) => {
  const { data: cyberIQBadges } = useCyberIQBadgesQuery();

  if (!cyberIQBadges) {
    return null;
  }

  const filteredBadges: CyberIQBadge[] = badgeIds
    .map((id: string) => cyberIQBadges.find((badge: CyberIQBadge): boolean => badge.id === id))
    .filter((item: CyberIQBadge | undefined): item is CyberIQBadge => item !== undefined);

  const renderRemainingBadgesNumber = () => {
    if (badgeIds.length < max + 1) {
      return null;
    }

    const remainingBadges = badgeIds.length - max;

    return (
      <RemainingBadgesNumberContainer>
        <span>+</span>
        <span>{remainingBadges}</span>
      </RemainingBadgesNumberContainer>
    );
  };

  const renderBadges = () => {
    return filteredBadges.map((badge, index) =>
      index < max ? <img key={badge.id} src={badge.assetUrl} alt={`Badge ${badge.id}`} width={30} height={33} /> : null,
    );
  };

  const renderZeroState = () => {
    return (
      <NoBadgeContainer>
        <Icon icon={IconType.NO_BADGES} width={16} height={16} fill="transparent" />
        <NamedContentEntryText refKey="leaderboard" container="span" subKey="noBadges" />
      </NoBadgeContainer>
    );
  };

  return (
    <Container>
      <BadgeListContainer>
        {filteredBadges.length ? renderBadges() : renderZeroState()}
        {renderRemainingBadgesNumber()}
      </BadgeListContainer>
      {renderLinks && renderLinks()}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 16px;

  .view-all {
    display: none;
  }

  &:hover a.view-all {
    display: block;
  }
`;
const BadgeListContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

const RemainingBadgesNumberContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  background-color: ${({ theme }) => theme.colors.tableBorder};
  color: ${({ theme }) => theme.colors.heading};
  font-size: ${({ theme }) => theme.fontSizes.medium};
  font-weight: 500;
  padding: 4px 8px;
  border-radius: ${({ theme }) => theme.borderRadius.large};
  user-select: none;
`;

const NoBadgeContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: ${({ theme }) => theme.borderRadius.large};
  background-color: ${({ theme }) => theme.colors.tableBorder};
  filter: brightness(1.1);
  padding: 4px 8px;
  user-select: none;
  color: ${({ theme }) => theme.colors.heading};
`;
