import React, { Suspense } from 'react';
import { RouterProvider } from 'react-router';
import { GlobalWrapper } from './GlobalWrapper';
import { ZustandStoresProvider } from '../appState/StoresProvider';
import { fontFaces } from './fontFaces';
import { useFontFaces } from '../lib/hooks/useFontFaces';
import { useRouter } from '../lib/hooks/useRouter';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { RequestClientProvider } from '../lib/hooks/useRequestClient';
import { LanguageContextProvider } from '../lib/hooks/useLanguageContext';
import { Spinner } from '../components/Spinner/Spinner';
import { useTheme } from 'styled-components';
import { ColorThemeContentProvider } from '../lib/hooks/useColorTheme/useColorTheme';

const queryClient = new QueryClient();

export const App: React.FC = () => {
  useFontFaces(fontFaces, true);
  return (
    <ColorThemeContentProvider>
      <ZustandStoresProvider>
        <QueryClientProvider client={queryClient}>
          <RequestClientProvider>
            <LanguageContextProvider>
              <ReactQueryDevtools initialIsOpen={false} buttonPosition="bottom-left" />
              <GlobalWrapper>
                <Suspense fallback={<FullScreenSpinner />}>
                  <AppRouterProvider />
                </Suspense>
              </GlobalWrapper>
            </LanguageContextProvider>
          </RequestClientProvider>
        </QueryClientProvider>
      </ZustandStoresProvider>
    </ColorThemeContentProvider>
  );
};

const AppRouterProvider: React.FC = () => {
  const router = useRouter();
  return <RouterProvider router={router} />;
};

const FullScreenSpinner: React.FC = () => {
  const theme = useTheme();
  return (
    <div style={{ position: 'fixed', inset: 0, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Spinner size="48px" color={theme.colors.primary} />
    </div>
  );
};
