import type { ComponentType, FC, PropsWithChildren } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useQueryErrorResetBoundary } from '@tanstack/react-query';
import type { ErrorViewProps } from '../../components/ErrorView/ErrorView';

export function withErrorBoundary<P extends JSX.IntrinsicAttributes>(
  Component: ComponentType<P>,
  ErrorComponent: FC<ErrorViewProps>,
): ComponentType<P> {
  const C: ComponentType<P> = (props) => {
    return (
      <EB ErrorComponent={ErrorComponent}>
        <Component {...props} />
      </EB>
    );
  };
  C.displayName = `withErrorBoundary(${Component.displayName || Component.name})`;
  return C;
}

const EB: FC<PropsWithChildren<{ ErrorComponent: FC<ErrorViewProps> }>> = ({ children, ErrorComponent }) => {
  const { reset } = useQueryErrorResetBoundary();
  return (
    <ErrorBoundary
      onReset={reset}
      resetKeys={[location.pathname]}
      fallbackRender={({ error, resetErrorBoundary }) => (
        <ErrorComponent error={error} onTryAgain={resetErrorBoundary} />
      )}
    >
      {children}
    </ErrorBoundary>
  );
};
