import { type FC } from 'react';
import { Card } from '../Card';
import { ExpandableSection } from './components/ExpandableSection';
import { CompletedTrainingCard } from './components/CompletedTrainingCard';
import styled from 'styled-components';
import { NamedContentEntryText } from '../ContentEntryText/NamedContentEntryText';
import { useState } from 'react';
import { LabelledToggle } from '../Toggle';
import type { LabelledToggleOption } from '../Toggle/LabelledToggle/LabelledToggle';
import {
  useManagerTeamCampaignsQuery,
  type ManagerTeamCampaignListResponse,
} from '../../apiHooks/useManagerTeamCampaignsQuery';
import { ManagerTeamTrainingCard } from './components/ManagerTeamTrainingCard';
import { RTLFixSpan } from './utils/RTLFixSpan';

export const ManagerTeamTrainingWidget: FC = () => {
  const {
    data: { completed: completedCampaigns, mandatory: mandatoryCampaigns },
  } = useManagerTeamCampaignsQuery(true);

  const toggleLabels: LabelledToggleOption[] = [
    {
      label: <NamedContentEntryText container="span" refKey="yourTraining" subKey="mandatory" />,
      value: 'mandatory',
      selected: true,
    },
    {
      label: <NamedContentEntryText container="span" refKey="yourTraining" subKey="completed" />,
      value: 'completed',
    },
  ];

  const [selectedTrainingType, setSelectedTrainingType] = useState(toggleLabels[0]?.value);
  const onFilterChange = (index: number) => {
    setSelectedTrainingType(toggleLabels[index]?.value);
  };

  return (
    <Card
      title={<NamedContentEntryText container={'span'} refKey="managerSectionTitles" subKey="cybersecurityTraining" />}
    >
      <NamedContentEntryText container={'span'} refKey="managerTeamCyberSecurityTraining" subKey="description" />
      <FiltersBar>
        <LabelledToggle
          fullWidth
          labels={toggleLabels}
          onSelect={(selectedIndex: number) => onFilterChange(selectedIndex)}
        />
      </FiltersBar>
      <ManagerTeamTrainingsContainer>
        {mandatoryCampaigns.map((campaign: ManagerTeamCampaignListResponse) => (
          <ManagerTeamTrainingCard
            key={campaign.id}
            title={campaign.name}
            learnersCompletedTotal={campaign.learnersCompletedTotal || 0}
            totalLearners={campaign.totalLearners || 0}
            dueDate={campaign.dueDate}
            estimatedCompletionTime={campaign.estimatedCompletionTime || 25}
            nudgeActionText={
              <NamedContentEntryText container="span" refKey="managerTeamCyberSecurityTraining" subKey="nudge" />
            }
          />
        ))}
      </ManagerTeamTrainingsContainer>
      <ExpandableSectionsContainer>
        <ExpandableSection
          title={
            <label>
              <NamedContentEntryText container="span" refKey="yourTraining" subKey="completed" />{' '}
              <RTLFixSpan>({completedCampaigns.length})</RTLFixSpan>
            </label>
          }
          defaultOpen={!!completedCampaigns.length}
        >
          <ExpandableSectionContent>
            {completedCampaigns.map((campaign: ManagerTeamCampaignListResponse) => (
              <CompletedTrainingCard key={campaign.id} campaign={campaign} />
            ))}
          </ExpandableSectionContent>
        </ExpandableSection>
      </ExpandableSectionsContainer>
      <MobileContainer>
        {selectedTrainingType === 'mandatory' &&
          mandatoryCampaigns.map((campaign: ManagerTeamCampaignListResponse) => (
            <ManagerTeamTrainingCard
              key={campaign.id}
              title={campaign.name}
              learnersCompletedTotal={campaign.learnersCompletedTotal || 0}
              totalLearners={campaign.totalLearners || 0}
              dueDate={campaign.dueDate}
              estimatedCompletionTime={campaign.estimatedCompletionTime || 25}
              nudgeActionText={
                <NamedContentEntryText container="span" refKey="managerTeamCyberSecurityTraining" subKey="nudge" />
              }
            />
          ))}
        {/*Completed*/}
        {selectedTrainingType === 'completed' &&
          completedCampaigns.map((campaign: ManagerTeamCampaignListResponse) => (
            <CompletedTrainingCard key={campaign.id} campaign={campaign} />
          ))}
      </MobileContainer>
    </Card>
  );
};

const ExpandableSectionsContainer = styled.div`
  @media (max-width: 768px) {
    display: none;
  }
`;

const ExpandableSectionContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  min-height: 10px;
`;

const FiltersBar = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: flex;
  }
`;

const MobileContainer = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
`;

const ManagerTeamTrainingsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  @media (max-width: 768px) {
    display: none;
  }
`;
