import { useEffect, useMemo, useState } from 'react';

export const useMediaQuery = (query: string): boolean => {
  const mediaQueryList = useMemo(() => window.matchMedia(query), [query]);
  const [match, setMatch] = useState(mediaQueryList.matches);

  useEffect(() => {
    const handleMatchChange = (ev: MediaQueryListEvent): void => setMatch(ev.matches);
    mediaQueryList.addEventListener('change', handleMatchChange);
    return () => {
      mediaQueryList.removeEventListener('change', handleMatchChange);
    };
  }, [mediaQueryList, query]);

  return match;
};
