import { type FC, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { LoginForm } from './components/LoginForm';
import { useZustandStore } from '../../appState/StoresProvider';
import { type LoginThemeConfig, LoginThemes, useDefaultLoginTheme } from '../../appState/hooks/useDefaultLoginTheme';
import { Spinner } from '../Spinner/Spinner';
import type { LoginSettings } from '../../appState/stateCreators/auth';
import { validateEmail } from '../../lib/string/validateEmail';

export const Login: FC = () => {
  const requestOTP = useZustandStore('auth', (v) => v.requestOTP);
  const waiting = useZustandStore('auth', (v) => v.isFetching);
  const loginSettings = useZustandStore('auth', (v) => 'loginSettings' in v && v.loginSettings);

  const [error, setError] = useState<string | undefined>(undefined);
  const defaultLoginTheme: LoginThemeConfig = useDefaultLoginTheme();

  const handleLogin = (email: string) => {
    if (!email) {
      setError('Email cannot be empty.');
      return;
    }

    if (!validateEmail(email)) {
      setError('Please insert a valid email.');
      return;
    }

    requestOTP(email).then((status) => {
      if (!status.success) {
        setError(status.error);
      }
    });
  };

  const theme = useTheme();

  return (
    <LoginContainer defaultLoginTheme={defaultLoginTheme} loginSettings={loginSettings}>
      <LoginContent className="login-content">
        {loginSettings ? (
          <LoginForm
            guidanceText={loginSettings.signInGuidanceText}
            onSubmit={handleLogin}
            logoUrl={loginSettings.logoUrl}
            errorText={error}
            waiting={waiting}
            isEnabled={loginSettings.portalEnabled}
          />
        ) : (
          <Spinner size={'32px'} color={theme.colors.primary} />
        )}
        {(loginSettings && loginSettings.signInTheme) === LoginThemes.splitScreen ? (
          <Image src={(loginSettings && loginSettings.backgroundUrl) || defaultLoginTheme.defaultSplitScreenURL} />
        ) : null}
      </LoginContent>
    </LoginContainer>
  );
};

const LoginContainer = styled.div.withConfig({
  shouldForwardProp: (prop) => prop !== 'defaultLoginTheme' && prop !== 'loginSettings',
})<{ defaultLoginTheme: LoginThemeConfig; loginSettings: false | LoginSettings }>`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;
  background: ${({ defaultLoginTheme, loginSettings, theme }) =>
    (loginSettings && loginSettings.signInTheme) === LoginThemes.fullBackground
      ? `url(${(loginSettings && loginSettings.backgroundUrl) || defaultLoginTheme.defaultFullBackgroundURL})`
      : theme.colors.pageBackground};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  ${({ loginSettings }) =>
    (loginSettings && loginSettings.signInTheme) === LoginThemes.fullBackground &&
    `
      > .login-content {
        width: unset;
      }
    `}
`;

const LoginContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.panelBackground};
  border-radius: ${({ theme }) => theme.borderRadius.normal};
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  height: 504px;
  width: 800px;

  @media (max-width: 768px) {
    flex-direction: column-reverse;
    height: auto;
    width: unset;
  }
`;

const Image = styled.img`
  display: block;
  border-radius: 0 ${({ theme }) => theme.borderRadius.normal} ${({ theme }) => theme.borderRadius.normal} 0;
  width: 100%;
  max-width: 400px;
  height: 100%;
  object-fit: contain;

  @media (max-width: 768px) {
    max-height: 350px;
    max-width: 343px;
    border-radius: ${({ theme }) => theme.borderRadius.normal} ${({ theme }) => theme.borderRadius.normal} 0 0;
  }
`;
