import { useLPSuspenseQuery } from '../lib/hooks/useLPQuery';

export function useCampaignsQuery(showAll: boolean) {
  return useLPSuspenseQuery<UseCampaignsQueryResponse>(
    ['campaigns', showAll],
    `/campaigns?showAll=${showAll.toString().toLowerCase()}`,
  );
}

export type UseCampaignsQueryResponse = {
  mandatory: EnrolledCampaignListResponse[];
  completed: EnrolledCampaignListResponse[];
  voluntary: EnrolledCampaignListResponse[];
};

export type EnrolledCampaignListResponse = {
  id: string; // Guid in C#
  name: string;
  status: 'notStarted' | 'inProgress' | 'completed';
  learnerStatus: 'noAction' | 'notStarted' | 'inProgress' | 'completed' | 'failed' | 'expired';
  voluntary: boolean;
  dueDate: string;
  isStarted: boolean;
  modulesProgress: number;
  modulesTotal: number;
  campaignUrl: string;
  pointsAward?: number;
};
