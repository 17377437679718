import { useEffect } from 'react';

import ky, { type Options } from 'ky';
import { useLoginStatus } from './useLoginStatus';
import { useZustandStore } from '../../StoresProvider';

const apiBaseURL = import.meta.env.REACT_APP_LP_API_BASE_URL as string;

export const usePortalGuard = () => {
  const loginStatus = useLoginStatus();
  const signOut = useZustandStore('auth', (v) => v.signOut);
  const tenantId = useZustandStore('auth', (v) => v.tenantId);

  useEffect(() => {
    const checkPortalEnabled = async () => {
      try {
        const kyOpts: Options = {
          ...(tenantId ? { headers: { 'OT-Customer-Id': tenantId } } : {}),
        };
        const { portalEnabled } = await ky
          .get(`${apiBaseURL}/login/settings`, kyOpts)
          .json<{ portalEnabled: boolean }>();

        if (!portalEnabled) {
          console.log('Portal is disabled. Logging out user...');
          if (loginStatus === 'loggedIn') {
            signOut();
          }
        }
      } catch (error) {
        console.error('Failed to check portalEnabled:', error);
      }
    };

    if (tenantId) {
      checkPortalEnabled().then();
    }
  }, [loginStatus, tenantId, signOut]);
};
