import { type TopBarUIProps } from '../TopBarUI';
import { useMemo } from 'react';
import { useZustandStore } from '../../../appState/StoresProvider';
import { useMeQuery } from '../../../apiHooks/useMeQuery';
import { useAvatarQuery } from '../../../apiHooks/useAvatarQuery';
import { useQueryClient } from '@tanstack/react-query';
import type { MenuPage } from '../../../topBarMenuPages/MenuPage';

export function useTopBarUIProps(topBarMenuPages: MenuPage[], managerView?: boolean): TopBarUIProps {
  const signOut = useZustandStore('auth', (v) => v.signOut);
  const loginSettings = useZustandStore('auth', (v) => 'loginSettings' in v && v.loginSettings);
  const { data: meData } = useMeQuery();
  const { data: avatarData } = useAvatarQuery();
  const queryClient = useQueryClient();
  return useMemo(
    () =>
      ({
        pages: topBarMenuPages,
        user: meData
          ? {
              name: meData.name || null,
              role: (meData.portalRole as 'learner' | 'manager') || null,
              avatar: avatarData?.avatar || null,
            }
          : {
              name: null,
              role: null,
              avatar: avatarData?.avatar || null,
            },
        logoUrl: (loginSettings && loginSettings.logoUrl) || '',
        onSignOut: () => {
          queryClient.clear();
          signOut();
        },
        managerView,
      }) satisfies TopBarUIProps,
    [topBarMenuPages, meData, avatarData?.avatar, loginSettings, managerView, queryClient, signOut],
  );
}
