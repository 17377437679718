import type { FC } from 'react';
import SuccessSVG from './assets/success.svg';
import FailSVG from './assets/fail.svg';
import NeutralSVG from './assets/neutral.svg';
import { type SVGRSvg } from '../../../lib/types/SVGRSvg';

type IconProps = {
  icon: string;
  width?: string | number;
  height?: string | number;
  className?: string;
};

export const StatusIcon: FC<IconProps> = ({ icon, width = '24', height = '24', className = '' }: IconProps) => {
  const SelectedIcon = IconMapper[icon];

  if (!SelectedIcon) {
    console.error(`Icon "${icon}" not found.`);
    return null;
  }

  return <SelectedIcon width={width} height={height} className={className} />;
};

export enum StatusIconType {
  SUCCESS = 'Success',
  FAIL = 'Fail',
  NEUTRAL = 'Neutral',
}

const IconMapper: Record<string, SVGRSvg> = {
  [StatusIconType.SUCCESS]: SuccessSVG,
  [StatusIconType.FAIL]: FailSVG,
  [StatusIconType.NEUTRAL]: NeutralSVG,
};
