import React, { useMemo, useState, type FC } from 'react';
import {
  DateLabel,
  DateLabelContainer,
  IconContainer,
  MainTrainingCard,
  ProgressBar,
  ProgressBarContainer,
  ProgressFill,
  Row,
  Title,
  TitleContainer,
  TrainingPrimaryButton,
} from '../TrainingCard';
import styled from 'styled-components';
import { useLanguageContext } from '../../../../lib/hooks/useLanguageContext';
import { Icon, IconType } from '../../../Props/Icon/Icon';
import { CardStatus } from '../../enums/CardStatus';
import { getCardStatus } from '../../utils/getCardStatus';
import { TrainingCompletionModal } from '../TrainingCompletionModal/TrainingCompletionModal';
import { NamedContentEntryText } from '../../../ContentEntryText/NamedContentEntryText';
import { Tooltip } from '../../../Tooltip';

type ManagerTeamTrainingCardProps = {
  title: string;
  dueDate: string;
  totalLearners: number;
  learnersCompletedTotal: number;
  estimatedCompletionTime: number;
  nudgeActionText: React.ReactNode;
};

export const ManagerTeamTrainingCard: FC<ManagerTeamTrainingCardProps> = ({
  title,
  dueDate,
  totalLearners,
  learnersCompletedTotal,
  estimatedCompletionTime,
  nudgeActionText,
}: ManagerTeamTrainingCardProps) => {
  const dd = useMemo(() => new Date(dueDate), [dueDate]);
  const cardStatus: CardStatus = getCardStatus(dd);
  const progress = totalLearners > 0 ? (learnersCompletedTotal / totalLearners) * 100 : 0;
  const [langCode] = useLanguageContext();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const onNudgeAllLearners = () => {
    console.log('TODO: Integrate Nudge All Learners BE request');
  };

  const handleOpenTrainingUsersModal = () => {
    if (!isModalOpen) {
      setIsModalOpen(true);
    }
  };

  return (
    <>
      <MainTrainingCard cardStatus={cardStatus}>
        <Row>
          <TitleContainer>
            <IconContainer>
              <Icon icon={IconType.GOAL_FLAG} width={24} height={24} className="rtl-flip" />
            </IconContainer>
            <Title>{title}</Title>
          </TitleContainer>
          <DateLabelContainer cardStatus={cardStatus}>
            <StyledIconMobile width={16} height={16} icon={IconType.CALENDAR} />
            <DateLabel>{dd.toLocaleString(langCode, { month: 'short', day: 'numeric', year: 'numeric' })}</DateLabel>
            <StyledIcon width={16} height={16} icon={IconType.CALENDAR} />
          </DateLabelContainer>
        </Row>
        <Row>
          <ProgressBarContainer>
            <ProgressBar>
              <ProgressFill progress={progress} />
            </ProgressBar>
          </ProgressBarContainer>
          <StatsContainer>
            <Tooltip
              offset={15}
              content={
                <TooltipInfoContainer>
                  <NamedContentEntryText
                    refKey="managerTeamCyberSecurityTraining"
                    subKey="seeTrainingCompletions"
                    container="span"
                  />
                </TooltipInfoContainer>
              }
              placement="top"
            >
              <StatsItemTrainingCompleation isClickable={!isModalOpen} onClick={handleOpenTrainingUsersModal}>
                <Icon width={16} height={16} icon={IconType.USER_COUNT} fill="none" />
                <span>
                  <CompletedLearnersCount>{learnersCompletedTotal}</CompletedLearnersCount> / {totalLearners}
                </span>
              </StatsItemTrainingCompleation>
            </Tooltip>
            <StatsItem>
              <Icon width={16} height={16} icon={IconType.CLOCK} fill="none" />
              <span>{estimatedCompletionTime} min</span>
            </StatsItem>
          </StatsContainer>
          <TrainingPrimaryButton
            onClick={() => {
              console.log('TODO: Implement Nudge Team Modal');
            }}
          >
            {nudgeActionText}
          </TrainingPrimaryButton>
        </Row>
      </MainTrainingCard>
      <TrainingCompletionModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        nudgeAllLearners={onNudgeAllLearners}
        statsCard={{
          title,
          dueDate,
          totalLearners,
          learnersCompletedTotal,
          estimatedCompletionTime,
        }}
      />
    </>
  );
};

const StatsItem = styled.div`
  display: flex;
  gap: 6px;
  color: ${({ theme }) => theme.colors.normal};
`;

const StatsItemTrainingCompleation = styled(StatsItem)<{ isClickable: boolean }>`
  cursor: ${({ isClickable }) => (isClickable ? 'pointer' : 'default')};
  ${({ isClickable, theme }) =>
    isClickable &&
    `
    &:hover {
      background-color: ${theme.colors.pageBackground};
      padding: 4px;
      border-radius: 4px;
    }
  `}
`;

const StatsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  @media (max-width: 768px) {
    justify-content: space-between;
  }
`;

const CompletedLearnersCount = styled.span`
  font-weight: 600;
`;

const StyledIcon = styled(Icon)`
  @media (max-width: 768px) {
    display: none;
  }
`;

const StyledIconMobile = styled(Icon)`
  display: none;
  @media (max-width: 768px) {
    display: flex;
  }
`;

const TooltipInfoContainer = styled.div`
  padding: 4px;
  width: 160px;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.insetPanelBackground};
  background-color: ${({ theme }) => theme.colors.panelBackground};
`;
