import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
  html,body{
    height:100%;
    scroll-behavior: smooth;
    font-family: Poppins, sans-serif;
    font-size: ${({ theme }) => theme.fontSizes.normal};
    background:${({ theme }) => theme.colors.pageBackground};
    color:${({ theme }) => theme.colors.normal};
  }
  h1,h2,h3,h4,h5,h6{
    color:${({ theme }) => theme.colors.heading};
  }
  h1,h2{
    font-size:${({ theme }) => theme.fontSizes.heading};
    text-transform: uppercase;
    font-weight: bold;
  }
  h3,h4,h5,h6{
    font-size:${({ theme }) => theme.fontSizes.normal};
    font-weight: bold;
  }
  a{
    color:${({ theme }) => theme.colors.anchor};
    text-decoration: none;
  }
  
  body{
    padding: 0;
  }
  html:focus-within{
    scroll-behavior: smooth; 
  }
  *{
    margin: 0; 
    padding: 0; 
    box-sizing: border-box; 
    &::before,&::after{
      box-sizing: border-box; 
    }
  }
  input, button, textarea, select{
    font: inherit; 
  }
  #root{
    height: 100%;
    min-height: 100%;
    position: relative;
    padding: 16px;

    @media (max-width: 768px) {
      padding: 0;
    }
  }
  html[dir='rtl']{
    svg, img{
      &.rtl-flip{
        transform: scaleX(-1);
      }
    }
  }
`;
